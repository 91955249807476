import {InvoiceType} from "@Accountancy/Invoice/Types/invoice";

export type InvoiceTypeRow = { type: InvoiceType; label: string };
export const InvoiceTypes: InvoiceTypeRow[] = [
  { type: 'VAT', label: 'Faktura VAT' },
  { type: 'PROFORMA', label: 'Faktura Proforma' },
  { type: 'BILL', label: 'Rachunek' },
  { type: 'RECIPT', label: 'Paragon' },
  { type: 'ADVANCE', label: 'Faktura zaliczkowa' },
  { type: 'FINAL', label: 'Faktura końcowa' },
  { type: 'CORRECTION', label: 'Faktura korekta' },
  { type: 'VAT_MP', label: 'Faktura MP' },
  { type: 'MARGIN', label: 'Faktura marża' },
  { type: 'CORRECTION_NOTE', label: 'Nota korygująca' },
  { type: 'ACCOUNTING_NOT', label: 'Nota księgowa' },
  { type: 'IMPORT_SERVICE', label: 'Import usług' },
  { type: 'IMPORT_SERVICE_EU', label: 'Import usług z UE' },
  { type: 'IMPORT_PRODUCTS', label: 'Import towarów - procedura uproszczona' },
  { type: 'EXPORT_PRODUCTS', label: 'Eksport towarów' },
  { type: 'OTHER', label: 'Inna faktura' },
];

export const typeLabel = (type: InvoiceType): string => {
  return InvoiceTypes.find(s => s.type === type)?.label ?? 'Nieznany';
};
