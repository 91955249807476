import MenuPicker, { MenuPickerOption, MenuPickerProps } from '@Components/Form/MUI/MenuPicker';
import ColoredIconBox from '@Components/Theme/Common/ColoredIconBox';
import Loader from '@Components/Theme/Common/Loader';
import useTaxes from '@Core/Accountancy/Hooks/useTaxes';
import { ValueAddedTax } from '@Core/Accountancy/ValueAddedTax';
import { PropsWithChildren, useMemo } from 'react';

type TaxOption = MenuPickerOption<string | null, ValueAddedTax>;
type TaxPickerProps = Omit<MenuPickerProps<string | null, ValueAddedTax>, 'options'>;

function TaxesMenu({ value, onChange, disabled, children }: PropsWithChildren<TaxPickerProps>) {
  const { data: taxes, isLoading } = useTaxes();

  const taxesOptions: TaxOption[] = useMemo<TaxOption[]>(() => {
    return taxes.map<TaxOption>(tax => ({
      value: tax['@id'],
      label: tax.name ?? '',
      data: tax,
    }));
  }, [taxes]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <MenuPicker<string | null, ValueAddedTax>
      value={value}
      options={taxesOptions}
      defaultColor={'#303030'}
      defaultBackground={'#fff'}
      onChange={onChange}
      disabled={disabled}
    >
      {children || <ColoredIconBox value={'Vat'} background={disabled ? '#eff2f7' : '#fff'} color={'#636363'} />}
    </MenuPicker>
  );
}

export default TaxesMenu;
