import { createModuleColumnConfig, setValuesToFormRecord } from '../../../helpers/ModuleUtils';
import InvoiceExpandedRow from './Components/List/InvoiceExpandedRow';
import DepartmentModule from '@Accountancy/Department/Department';
import InvoiceContextHeader from '@Accountancy/Invoice/Components/ContextHeader/InvoiceContextHeader';
import LanguageField from '@Accountancy/Invoice/Components/Form/LanguageField';
import DataGridHeader from '@Accountancy/Invoice/Components/List/DataGridHeader';
import PaymentDeadlineFilter from '@Accountancy/Invoice/Components/List/PaymentDeadlineFilter';
import StatusFilter from '@Accountancy/Invoice/Components/List/StatusFilter';
import TypeFilter from '@Accountancy/Invoice/Components/List/TypeFilter';
import InvoiceDefaultsConfiguration from '@Accountancy/Invoice/Configuration/InvoiceDefaultsConfiguration';
import InvoiceDepartmentsConfiguration from '@Accountancy/Invoice/Configuration/InvoiceDepartmentsConfiguration';
import InvoiceNumerationConfiguration from '@Accountancy/Invoice/Configuration/InvoiceNumerationConfiguration';
import { useInvoiceContextActions } from '@Accountancy/Invoice/Hooks/useInvoiceContextActions';
import { statusLabel } from '@Accountancy/Invoice/InvoiceStatuses';
import { InvoiceTypes, typeLabel } from '@Accountancy/Invoice/InvoiceTypes';
import InvoiceDefaultSection from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import { Invoice, InvoiceConfiguration, InvoicePayment, InvoicePosition, InvoiceType } from '@Accountancy/Invoice/Types/invoice';
import PrintItemButton from '@Components/DataGrid/Button/PrintItemButton';
import MoneyView from '@Components/View/MoneyView';
import Contractor from '@Contractor/Contractor';
import useFetchModuleConfig from '@Core/Hooks/useFetchModuleConfig';
import { Field } from '@CustomFields/Field';
import { EmployeeWithImageURL } from '@Employee/Employee/Types/Employee';
import { ArrowLeftOutlined, ArrowRightOutlined, AttachMoneyOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import CTMModule, { CTMApiMixed, CTMListColumn } from 'Modules/Core/Types/CTMModule';
import { get, post } from 'helpers/Axios';
import moment from 'moment/moment';
import { LocalStorageKey } from 'store/Accountancy/Reducer';

export const sumPositions = (positions: InvoicePosition[], field: keyof InvoicePosition): number => {
  const sum = positions.reduce((prev, curr) => {
    const partValue = curr[field];
    if (typeof partValue === 'number') {
      return prev + partValue;
    }
    return prev;
  }, 0);
  return parseFloat(sum.toFixed(2));
};
export const sumPayments = (payments: InvoicePayment[], field: keyof InvoicePayment): number => {
  const sum = payments.reduce((prev, curr) => {
    const partValue = curr[field];
    if (typeof partValue === 'number') {
      return prev + partValue;
    }
    return prev;
  }, 0);
  return parseFloat(sum.toFixed(2));
};

const columns: CTMListColumn<Invoice>[] = [
  {
    id: 'department.id',
    filterable: true,
    sortable: false,
    forceHidden: column => true,
    Header: 'Typ',
    minWidth: 160,
    flex: 1,
    ...createModuleColumnConfig('department', DepartmentModule),
  },
  {
    id: 'number',
    Header: 'Numer',
    accessor: ({ number, income }) => {
      const color = income ? 'success' : 'error';
      const IconComponent = income ? ArrowRightOutlined : ArrowLeftOutlined;

      return (
        <Box display="flex">
          <IconComponent color={color} />
          <AttachMoneyOutlined color={color} />
          <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
            {number}
          </Typography>
        </Box>
      );
    },
    filterable: true,
    sortable: true,
  },
  {
    id: 'externalNumber',
    Header: 'Numer zew. system',
    accessor: 'externalNumber',
    filterable: true,
    sortable: true,
  },
  {
    id: 'type',
    Header: 'Typ',
    accessor: ({ type }) => typeLabel(type),
    filterable: true,
    Filter: TypeFilter,
    sortable: false,
    noBreak: true,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => statusLabel(status),
    filterable: true,
    Filter: StatusFilter,
    sortable: false,
    noBreak: true,
  },
  {
    id: 'seller.id',
    Header: 'Sprzedawca',
    filterable: true,
    sortable: false,
    minWidth: 160,
    ...createModuleColumnConfig('seller', Contractor),
  },
  {
    id: 'buyer.id',
    Header: 'Kupujący',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('buyer', Contractor),
  },
  {
    id: 'totalPriceNetHomeCurrency',
    Header: 'Netto',
    accessor: ({ totalPriceNetHomeCurrency, totalPriceNet, currency, exchangeRate, issueDate, exchangeInfoDate }) => (
      <MoneyView
        value={totalPriceNet}
        homeCurrencyValue={totalPriceNetHomeCurrency}
        currency={currency}
        exchangeDate={exchangeInfoDate ?? issueDate}
        exchangeRate={exchangeRate}
      />
    ),
    filterable: false,
    sortable: true,
  },
  {
    id: 'totalPriceVatHomeCurrency',
    Header: 'VAT',
    accessor: ({ totalPriceVatHomeCurrency, totalPriceVat, currency, exchangeRate, issueDate, exchangeInfoDate }) => (
      <MoneyView
        value={totalPriceVat}
        homeCurrencyValue={totalPriceVatHomeCurrency}
        currency={currency}
        exchangeDate={exchangeInfoDate ?? issueDate}
        exchangeRate={exchangeRate}
      />
    ),
    filterable: false,
    sortable: true,
  },
  {
    id: 'totalPriceGrossHomeCurrency',
    Header: 'Brutto',
    accessor: ({ totalPriceGrossHomeCurrency, totalPriceGross, currency, exchangeRate, issueDate, exchangeInfoDate }) => (
      <MoneyView
        value={totalPriceGross}
        homeCurrencyValue={totalPriceGrossHomeCurrency}
        currency={currency}
        exchangeDate={exchangeInfoDate ?? issueDate}
        exchangeRate={exchangeRate}
      />
    ),
    filterable: false,
    sortable: true,
  },
  {
    id: 'amountPaidGrossHomeCurrency',
    Header: 'Opłacona kwota',
    accessor: ({ amountPaidGrossHomeCurrency, amountPaidGross, currency, exchangeInfoDate, issueDate, exchangeRate }) => (
      <MoneyView
        value={amountPaidGross}
        homeCurrencyValue={amountPaidGrossHomeCurrency}
        currency={currency}
        exchangeDate={exchangeInfoDate ?? issueDate}
        exchangeRate={exchangeRate}
      />
    ),
    filterable: false,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'issueDate',
    Header: 'Data wystawienia',
    accessor: ({ issueDate }) => moment(issueDate).format('lll'),
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'daysAfterPaymentDeadline',
    Header: 'Termin płatności',
    accessor: ({ daysAfterPaymentDeadline, paymentDeadline }) =>
      null !== daysAfterPaymentDeadline ? moment(paymentDeadline).format('lll') : null,
    filterable: true,
    Filter: PaymentDeadlineFilter,
    filterPropertyName: 'paymentDeadline',
    orderPropertyName: 'paymentDeadline',
    sortable: true,
    noBreak: true,
  },
];

type CreateFromClientOrderInput = {
  clientOrder: string;
  invoiceType: InvoiceType;
  department: null;
};

type CustomActions = {
  createFromClientOrder: (data: CreateFromClientOrderInput) => Promise<Invoice>;
};

export type InvoiceModule = CTMModule<Invoice, CTMApiMixed, CTMApiMixed, CustomActions>;

const prepareFetchedRecord: (record: Partial<Invoice>, allFields: Field[]) => Partial<Invoice> = (record, allFields) => {
  return setValuesToFormRecord(record, allFields, {
    positions: record.positions
      ?.map(el => ({
        ...el,
        priceUnitNet: parseFloat((el.priceUnitNet / 100).toFixed(2)),
        priceUnitGross: parseFloat((el.priceUnitGross / 100).toFixed(2)),
        priceTotalNet: parseFloat((el.priceTotalNet / 100).toFixed(2)),
        priceTotalGross: parseFloat((el.priceTotalGross / 100).toFixed(2)),
      }))
      .sort((a, b) => a.seq - b.seq),
    payments: record.payments?.map(el => ({
      ...el,
      priceTotalGross: parseFloat((el.priceTotalGross / 100).toFixed(2)),
    })),
    correction: record.correction ? prepareFetchedRecord({ ['@formValues']: {}, ...record.correction }, allFields) : null,
  });
};

const module: InvoiceModule = {
  id: '76c6b67d-634b-4fa3-9ee1-24b7b30cadcd',
  dataClass: 'CTM\\Accountancy\\Entity\\Invoice',
  urlPrefix: 'accountancy-invoice',
  name: 'Faktury',
  role: 'ACCOUNTANCY_INVOICE',
  api: {
    item: {
      get: ({ id }) => `/accountancy/invoices/${id}`,
      put: ({ id }) => `/accountancy/invoices/${id}`,
      delete: ({ id }) => `/accountancy/invoices/${id}`,
    },
    collection: {
      get: `/accountancy/invoices`,
      post: `/accountancy/invoices`,
    },
    custom: {
      createFromClientOrder: data => post<Invoice>('/accountancy/invoices/from-client-order', data),
    },
  },
  recordLabel: ({ number, income, type }) =>
    `${InvoiceTypes.find(el => el.type === type)?.label} (${income ? 'Przychód' : 'Koszt'}) ${number ? ` - ${number}` : ''}`,
  form: {
    disableCreateNewRecord: true,
    sectionComponents: {
      ['7abe5de2-bdc3-4423-abd1-f5eda3897f03']: InvoiceDefaultSection,
    },
    fieldComponents: {
      lang: LanguageField,
      secondLang: LanguageField,
    },
    defaultRecord: (allFields: Field[], currentUser: EmployeeWithImageURL, queryParams: any, config: InvoiceConfiguration) =>
      new Promise(res => {
        const income = queryParams?.income === 'true';
        const rawPrefix = income ? 'sellerRaw' : 'buyerRaw';
        const invoiceType: InvoiceType = queryParams?.type ?? InvoiceTypes[0].type;

        const invoice: Partial<Invoice> = {
          income,
          type: invoiceType,
          issuer: currentUser['@id'],
          issueDate: moment().format('YYYY-MM-DD'),
          [income ? 'seller' : 'buyer']: config.InvoiceDefaults.innerContractor,
          issueAddress: {
            post: config.InvoiceDefaults.issueAddress.post,
            city: config.InvoiceDefaults.issueAddress.city,
            province: config.InvoiceDefaults.issueAddress.province,
            district: config.InvoiceDefaults.issueAddress.district,
            street: config.InvoiceDefaults.issueAddress.street,
            house: config.InvoiceDefaults.issueAddress.house,
            flat: config.InvoiceDefaults.issueAddress.flat,
            country: config.InvoiceDefaults.issueAddress.country,
            comment: '',
          },
        };

        if (typeof config.InvoiceDefaults.innerContractor === 'object') {
          invoice[`${rawPrefix}.name`] = config.InvoiceDefaults.innerContractor?.name;
          invoice[`${rawPrefix}.taxNumberPrefix`] = config.InvoiceDefaults.innerContractor?.tin.prefix;
          invoice[`${rawPrefix}.taxNumber`] = config.InvoiceDefaults.innerContractor?.tin.number;
          invoice[`${rawPrefix}.post`] = config.InvoiceDefaults.innerContractor?.address.post;
          invoice[`${rawPrefix}.city`] = config.InvoiceDefaults.innerContractor?.address.city;
          invoice[`${rawPrefix}.province`] = config.InvoiceDefaults.innerContractor?.address.province;
          invoice[`${rawPrefix}.district`] = config.InvoiceDefaults.innerContractor?.address.district;
          invoice[`${rawPrefix}.street`] = config.InvoiceDefaults.innerContractor?.address.street;
          invoice[`${rawPrefix}.house`] = config.InvoiceDefaults.innerContractor?.address.house;
          invoice[`${rawPrefix}.flat`] = config.InvoiceDefaults.innerContractor?.address.flat;
          invoice[`${rawPrefix}.country`] = config.InvoiceDefaults.innerContractor?.address.country;
        }

        if (queryParams?.fromDocument) {
          get(module.api.item.get({ id: queryParams.fromDocument })).then(data => {
            delete data.transactions;
            const prepared = module.form?.prepareFetchedRecord?.(data, allFields) ?? {};
            let tmp: Partial<Invoice> = { ['@formValues']: {} };

            let valuesToSet: Partial<Invoice> = {
              ...invoice,
              number: undefined,
              type: invoiceType,
              issuer: invoice.issuer,
              seller: prepared.seller,
              sellerRaw: prepared.sellerRaw,
              buyerRaw: prepared.buyerRaw,
              buyer: prepared.buyer,
              income: prepared.income,
              parent: prepared,
              createdFrom: prepared,
            };

            if (invoiceType === 'CORRECTION') {
              tmp = JSON.parse(JSON.stringify(prepared));
              delete tmp['@id'];
              delete tmp.id;
              delete tmp.number;

              valuesToSet = {
                ...valuesToSet,
                correction: prepared,
              };
            }

            if (
              [
                'VAT',
                'PROFORMA',
                'BILL',
                'RECIPT',
                'ADVANCE',
                'FINAL',
                'CORRECTION',
                'VAT_MP',
                'OTHER',
                'MARGIN',
                'IMPORT_SERVICE',
                'IMPORT_SERVICE_EU',
                'IMPORT_PRODUCTS',
                'EXPORT_PRODUCTS',
              ].includes(invoiceType)
            ) {
              valuesToSet.positions =
                prepared.positions?.map((el: any) => {
                  const position = { ...el };
                  delete position['@id'];
                  delete position.id;
                  return position;
                }) ?? [];
            }

            res(setValuesToFormRecord(tmp, allFields, valuesToSet));
          });
        } else {
          invoice.sellDate = moment().format('YYYY-MM-DD');
          res(setValuesToFormRecord({ '@formValues': {} }, allFields, invoice));
        }
      }),
    forceReadonlyField: field => field.propertyPath === 'number',
    prepareFetchedRecord: prepareFetchedRecord,
    prepareRecordToSave: (record, allFields) => {
      const isUpdate = record.hasOwnProperty('@id');

      return {
        ...record,
        seller: record.seller?.['@id'] ?? record.seller,
        buyer: record.buyer?.['@id'] ?? record.buyer,
        issuer: record.issuer?.['@id'] ?? record.issuer,
        correction: record.correction?.['@id'] ?? record.correction,
        parent: record.parent?.['@id'] ?? record.parent,
        createdFrom: record.parent?.['@id'] ?? record.createdFrom,
        paymentType: record.paymentType?.['@id'] ?? record.paymentType,
        department: isUpdate
          ? undefined
          : localStorage.getItem(LocalStorageKey) === 'undefined'
          ? null
          : localStorage.getItem(LocalStorageKey),
        recipient: record.recipient?.['@id'] ?? record.recipient,
        positions: record.positions?.map((el, index) => ({
          ...el,
          seq: index + 1,
          unit: el.unit?.['@id'] ?? el.unit,
          tax: el.tax?.['@id'] ?? el.tax,
          product: el.product?.['@id'] ?? el.product,
          priceUnitNet: Math.round(el.priceUnitNet * 100) || 0,
          priceUnitGross: Math.round(el.priceUnitGross * 100) || 0,
          priceTotalNet: Math.round(el.priceTotalNet * 100) || 0,
          priceTotalGross: Math.round(el.priceTotalGross * 100) || 0,
        })),
        payments: record.payments?.map(el => ({
          ...el,
          priceTotalGross: Math.round(el.priceTotalGross * 100) || 0,
        })),
      };
    },
    useContextActions: props => useInvoiceContextActions(props.record),
  },
  list: {
    headerOverride: DataGridHeader,
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'issueDate', desc: true }],
    resolveQueryKey: rootstate => `accountancy-invoices-${rootstate.Accountancy.departmentIRI}`,
    canRenderSubRow: () => true,
    renderRowSubComponent: ({ row: { original } }, modalListActions) => <InvoiceExpandedRow invoice={original} />,
    rowStylesCallback: ({ original: { daysAfterPaymentDeadline } }) => {
      if (daysAfterPaymentDeadline !== null) {
        switch (true) {
          case daysAfterPaymentDeadline >= 31:
            return { backgroundColor: '#b71c1c', color: '#fff' };
          case daysAfterPaymentDeadline >= 14:
            return { backgroundColor: '#ef5350' };
          case daysAfterPaymentDeadline >= 0:
            return { backgroundColor: '#ffcdd2' };
          default:
            return {};
        }
      }

      return {};
    },
    inlineComponentPrependActions: (row, listRef) => (
      <>
        <PrintItemButton id={row.number} name={'Faktura'} downloadUrl={row['@id']} description={'Pobierz plik Faktury.'} label={false} />
      </>
    ),
  },
  configuration: {
    InvoiceDefaults: {
      name: 'Ogólne',
      component: InvoiceDefaultsConfiguration,
    },
    InvoiceNumeration: {
      name: 'Numeracja',
      component: InvoiceNumerationConfiguration,
    },
    Departments: {
      name: 'Działy',
      component: InvoiceDepartmentsConfiguration,
    },
  },
  contextHeader: InvoiceContextHeader,
};

export const useInvoiceConfiguration = () => useFetchModuleConfig<InvoiceConfiguration>(module.id);

export default module;
