import useToastFactory from '@Core/Hooks/useToastFactory';

type Configuration = {
  errorTitle: string;
};

const defaultConfiguration: Configuration = {
  errorTitle: 'Wystąpił błąd',
};

function useApiErrorHandler(configuration?: Partial<Configuration>) {
  const { createError } = useToastFactory();

  const cfg: Configuration = { ...defaultConfiguration, ...configuration };

  return error => {
    createError(cfg.errorTitle);
    if (error.hasOwnProperty('response') && error.response.data.hasOwnProperty('hydra:description')) {
      createError(error.response.data['hydra:description']);
    }
  };
}

export default useApiErrorHandler;
