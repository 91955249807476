export type OptimizerOptions = {
  width: number;
  quality: number;
};

export type Optimizer = (url: string) => string;

function noImagesOptimizer(options: OptimizerOptions): Optimizer {
  return (url: string): string => url;
}

function imgProxyImagesOptimizer(options: OptimizerOptions): Optimizer {
  return (url: string): string => `${process.env.REACT_APP_IMG_PROXY_URL}/_/w:${options.width}/q:${options.quality}/plain/${url}@webp`;
}

function useImagesOptimizer(options: OptimizerOptions): Optimizer {
  if (process.env.REACT_APP_IMG_PROXY_URL) {
    return imgProxyImagesOptimizer(options);
  }

  return noImagesOptimizer(options);
}

export default useImagesOptimizer;
