import ModuleListActionsColumn from '@Components/Module/ModuleListActionsColumn';
import CRUDModule from '@Core/CRUDModule';
import useFetchModuleData from '@Core/Hooks/useFetchModuleData';
import CTMModule, { CTMListColumn, CTMRecord, FormConfiguration, ListConfiguration } from '@Core/Types/CTMModule';
import { Card } from '@mui/material';
import DataGrid, { DataGridHandle, DataGridProps } from 'components/DataGrid';
import ModalFormWrapper, { ModalFormHandle } from 'components/Form/ModalFormWrapper';
import ModuleForm from 'components/Module/ModuleForm';
import SecuredView from 'components/Theme/Common/SecuredView';
import { useModuleContext } from 'context/ModulesContext';
import useErrorHandler from 'helpers/FormHandler';
import { FC, RefObject, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, CardBody } from 'reactstrap';
import { useAppSelector } from 'store';

type ModalActionsHeaderWithModalProps = {
  module?: any;
  afterSave?: any;
  list?: any;
  moduleFormComponent?: any;
  moduleFormComponentProps?: any;
  moduleFormWrapperProps?: any;
  overrideFormProps?: any;
};

const ModalActionsHeaderWithModal: FC<ModalActionsHeaderWithModalProps> = ({
  module,
  afterSave,
  list,
  moduleFormComponent,
  moduleFormComponentProps,
  moduleFormWrapperProps,
  overrideFormProps,
}) => {
  const createModal = useRef<ModalFormHandle>();
  const errorHandler = useErrorHandler();

  if (!module) {
    return null;
  }

  if (!module.configuration.form && !moduleFormComponent) {
    return <>Akcje</>;
  }

  if (moduleFormComponent) {
    moduleFormComponentProps = { ...moduleFormComponentProps, modal: createModal };
  }

  const ModuleFormComponent = moduleFormComponent ?? ModuleForm;

  return (
    <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`} alternativeContent={'Akcje'}>
      <ModalFormWrapper
        {...moduleFormWrapperProps}
        ref={createModal}
        title={`${module.configuration.name} - Dodaj`}
        form={
          <ModuleFormComponent
            list={list}
            {...moduleFormComponentProps}
            moduleName={module.configuration.urlPrefix}
            id={null}
            showContextActions={false}
            showBackButton={false}
            afterSave={record => {
              afterSave?.(record, { list, createModal });
              if (module.configuration.list.closeAfterListAction ?? true) {
                list?.current?.refresh();
                createModal?.current?.close();
              }
            }}
            onError={errorHandler}
            readonly={false}
            overrideFormProps={overrideFormProps}
          />
        }
      >
        <Button className="btn btn-success btn-sm w-100" color="primary">
          <i className="mdi mdi-plus" />
          &nbsp;Dodaj nowy
        </Button>
      </ModalFormWrapper>
    </SecuredView>
  );
};

export type InlineActionsHeaderProps = {
  listRef: RefObject<DataGridHandle>;
  module: CRUDModule<CTMModule<any>>;
  override: any;
  overrideFormProps: ModuleListProps<any>['overrideFormProps'];
  useModal: boolean;
};

export const InlineActionsHeader: FC<InlineActionsHeaderProps> = ({ module, override, listRef, useModal, overrideFormProps = {} }) => {
  if (!module) {
    return null;
  }

  if (module.configuration.form?.disableCreateNewRecord || overrideFormProps?.disableCreateNewRecord) {
    return <>Akcje</>;
  }

  if (useModal) {
    return <ModalActionsHeaderWithModal module={module} overrideFormProps={overrideFormProps} list={listRef} />;
  }

  return (
    <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`} alternativeContent={'Akcje'}>
      <Link to={override?.createUrl ?? module.createUrl} className="btn btn-success btn-sm w-100" color="primary">
        <i className="mdi mdi-plus" />
        &nbsp;Dodaj nowy
      </Link>
    </SecuredView>
  );
};

export const InlineListActions = (
  errorHandler,
  listRef: RefObject<DataGridHandle>,
  editModal: RefObject<ModalFormHandle>,
  showModal: RefObject<ModalFormHandle>,
  module: CRUDModule<CTMModule<any>>,
  override: any,
  overrideFormProps: ModuleListProps<any>['overrideFormProps'],
  useModal = false,
  moduleFormWrapperProps = {},
): CTMListColumn<any>[] => {
  if (!module || !module.configuration.form) {
    return [];
  }
  return [
    {
      id: 'actionsStickyRight',
      Header: () => (
        <InlineActionsHeader
          module={module}
          override={override}
          useModal={useModal}
          listRef={listRef}
          overrideFormProps={overrideFormProps}
        />
      ),
      disableSortBy: true,
      disableFilters: true,
      disableExpandAction: true,
      accessor: row => (
        <ModuleListActionsColumn
          row={row}
          errorHandler={errorHandler}
          listRef={listRef}
          editModal={editModal}
          showModal={showModal}
          module={module}
          override={override}
          overrideFormProps={overrideFormProps}
          useModal={useModal}
          moduleFormWrapperProps={moduleFormWrapperProps}
        />
      ),
    },
  ];
};

export type ModuleListProps<T extends CTMRecord = object> = {
  moduleName: string;
  useModal?: boolean;
  defaultFilters?: any;
  overrideFormProps?: FormConfiguration<T> & {
    disableCustomActions?: boolean;
    recordCallback?: (fields: any) => T | object;
  };
  overrideListProps?: Omit<ListConfiguration<T>, 'columns'> & {
    columns?: ((columns: CTMListColumn[]) => CTMListColumn<T>[]) | ListConfiguration<T>['columns'];
    disableTop?: boolean;
    fullWidth?: boolean;
    responsive?: boolean;
    className?: string;
    queryKey?: string;
    minRows?: number;
    defaultPerPage?: number;
    perPageOptions?: number[];
  };
};

const ModuleList: FC<ModuleListProps> = ({ moduleName, useModal = false, defaultFilters, overrideFormProps, overrideListProps }) => {
  const module = useModuleContext<CTMModule<CTMRecord>, true>(moduleName);
  const list = useRef<DataGridHandle>(null);
  const editModal = useRef<ModalFormHandle>(null);
  const showModal = useRef<ModalFormHandle>(null);
  const errorHandler = useErrorHandler();
  const { data: moduleData } = useFetchModuleData(module.configuration.id);

  const columns = useMemo(
    () => [
      ...(typeof overrideListProps?.columns === 'function'
        ? overrideListProps.columns((module.configuration.list?.columns ?? []) as CTMListColumn[])
        : overrideListProps?.columns ?? module?.configuration?.list?.columns ?? []),
      ...InlineListActions(errorHandler, list, editModal, showModal, module, undefined, overrideFormProps, useModal),
    ],
    [module],
  );
  const keyResolver = overrideListProps?.resolveQueryKey ?? module.configuration.list?.resolveQueryKey;
  const queryKey = useAppSelector(keyResolver ? keyResolver : () => false);

  const ListPrepend = module.configuration.list?.prependComponent;
  const exports = [...(moduleData?.exports ?? []), ...(module.configuration?.list?.customExports ?? [])];

  delete overrideListProps?.columns;

  const dataGridProps: DataGridProps = {
    url: module.configuration.list?.url ?? module.api.getAllUrl,
    fullWidth: false,
    responsive: true,
    disableTop: false,
    enableContextMenu: !useModal,
    className: 'table-clickable',
    exports: exports,
    defaultPerPage: 20,
    perPageOptions: [10, 20, 30, 40, 50],
    defaultOrderBy: module.configuration.list?.defaultOrderBy,
    renderRowSubComponent: module.configuration.list?.renderRowSubComponent,
    defaultFilters: defaultFilters ?? module.configuration.list?.defaultFilters,
    onRowClick: module.configuration.list?.onRowClick,
    rowStylesCallback: module.configuration.list?.rowStylesCallback,
    canRenderSubRow: module.configuration.list?.canRenderSubRow,
    selectionActions: module.configuration.list?.selectionActions,
    queryKey: queryKey,
    storeFilters: module.configuration.list?.storeFilters,
    prependButton: module.configuration.list?.prependButton,
    headerOverride: module.configuration.list?.headerOverride,
    ...overrideListProps,
    columns: columns,
  };

  return (
    <Card>
      <CardBody style={{ padding: 0 }}>
        {!overrideListProps?.disableTop && ListPrepend && <ListPrepend listRef={list} />}
        <DataGrid ref={list} {...dataGridProps} />
      </CardBody>
    </Card>
  );
};

export default ModuleList;
