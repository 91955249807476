import { confirmRemove } from 'common/sweetalerts';
import { useModule } from 'context/ModuleContext';

export default function useModuleDeleteAction(cb?: () => void): undefined | ((id: string | number) => void) {
  const { module } = useModule();

  if (!module.configuration.form || module.configuration.form.disableRemoveRecord) {
    return undefined;
  }

  return (id: string | number) => {
    confirmRemove(() => module.api.delete({ id })).then(() => {
      if (cb) {
        cb();
      }
    });
  };
}
