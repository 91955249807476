import ModuleList from '@Components/Module/ModuleList';
import CTMModule, { CTMRecord } from '@Core/Types/CTMModule';
import { ModuleProvider } from 'context/ModuleContext';
import { useModuleContext } from 'context/ModulesContext';
import { FC } from 'react';

const BaseLinkerIntegration: FC = kj => {
  const module = useModuleContext<CTMModule<CTMRecord>, true>('integration-base_linker');

  return (
    <>
      <ModuleProvider value={module}>
        <ModuleList moduleName={'integration-base_linker'} defaultFilters={undefined} overrideFormProps={{}} useModal={true} />
      </ModuleProvider>
    </>
  );
};

export default BaseLinkerIntegration;
