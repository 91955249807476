import ModuleContextMenu from '@Components/Module/ModuleContextMenu';
import EditableModuleField from '@Pages/Module/Component/EditableModuleField';
import { TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { useIsModuleContext } from 'context/ModuleContext';
import { Fragment, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  sticky: {
    position: 'sticky',
    right: 0,
    boxShadow: 'inset 1px 0px 0px 0px #dedede',
    background: 'inherit',
  },
  stickyRow: {
    position: 'sticky',
    top: -2,
    background: 'inherit',
    zIndex: 1,
    boxShadow: 'inset 1px -1px 0px 0px #dedede',
  },
});

function DataGridRow({
  row,
  rowIndex,
  queryKey,
  canRenderSubRow,
  renderRowSubComponent,
  onRowClick,
  rowStylesCallback,
  refetch,
  enableContextMenu,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const allowedCells = row.cells.filter(cell => typeof cell.column.forceHidden !== 'function' || !cell.column.forceHidden(cell.column));
  const isInNewModuleContext = useIsModuleContext();
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: MouseEvent<HTMLElement>) => {
    // Check if clicked element is <a> or <button>
    if (!enableContextMenu || (event.target instanceof HTMLElement && event.target.closest('a, button'))) {
      return; // Allow the system context menu
    }

    // Check if there is no selection or only caret selection (cursor)
    if (['None', 'Caret'].includes(window.getSelection()?.type ?? 'None')) {
      event.preventDefault(); // Prevent system menu only for custom menu cases
      event.stopPropagation();
      setContextMenu({
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      });
    }
  };
  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <Fragment key={`row_${queryKey}_${row.getRowProps().key}_${rowIndex}`}>
      <TableRow
        {...row.getRowProps({
          style: { backgroundColor: '#eff2f7' },
        })}
        onContextMenu={e => {
          if (isInNewModuleContext) {
            handleContextMenu(e);
          } else {
            row.getRowProps()?.onContextMenu?.(e);
          }
        }}
      >
        {allowedCells.map((cell, cellIndex) => {
          const allowExpanded = typeof canRenderSubRow === 'function' && canRenderSubRow(cell);
          return (
            <TableCell
              {...(!allowExpanded ? {} : { ...row.getToggleRowExpandedProps(), title: null })}
              {...cell.getCellProps({
                style: {
                  minWidth: cell.column.minWidth,
                  maxWidth: cell.column.maxWidth,
                  width: cell.column.width ?? 'auto',
                  padding: '2px 4px',
                  ...(rowStylesCallback ? rowStylesCallback(row, cell) : {}),
                },
                ...(onRowClick
                  ? {
                      onClick: () => onRowClick(row, { dispatch }, cell),
                    }
                  : {}),
              })}
              className={classnames({
                [classes.sticky]: true,
              })}
              size={'small'}
              key={`row_${queryKey}_${rowIndex}_${cell.id}_${cellIndex}`}
            >
              <div
                style={{
                  ...(cell.column.noBreak ? { whiteSpace: 'nowrap' } : {}),
                }}
              >
                {cell.column?.editable ? (
                  <EditableModuleField
                    fieldName={cell.column.id}
                    id={row.original.id}
                    value={row.original[cell.column.id]}
                    onChange={() => {
                      refetch();
                    }}
                  >
                    {cell.render('Cell')}
                  </EditableModuleField>
                ) : (
                  cell.render('Cell')
                )}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
      {renderRowSubComponent && row.isExpanded ? (
        <tr>
          <td colSpan={allowedCells.length} style={{ padding: 0 }}>
            {renderRowSubComponent({ row })}
          </td>
        </tr>
      ) : null}
      {isInNewModuleContext && contextMenu !== null && (
        <ModuleContextMenu
          menuProps={{
            anchorPosition: { top: contextMenu.mouseY, left: contextMenu.mouseX },
            open: true,
            anchorReference: 'anchorPosition',
          }}
          record={row.original}
          onClose={handleClose}
          afterDelete={() => refetch?.()}
        />
      )}
    </Fragment>
  );
}

export default DataGridRow;
