import { queryClient } from '../../../../../App';
import CheckboxColumn from '@Components/DataGrid/ColumnView/CheckboxColumn';
import { useModule } from 'context/ModuleContext';
import React, { useState } from 'react';
import Swal from 'sweetalert2';

interface Props {
  monthlySettlement: null | string;
  settlementYear: number;
  settlementMonth: number;
  isLocked: boolean;
}

const SettlementComponent: React.FC<Props> = ({ monthlySettlement, settlementYear, settlementMonth, isLocked }) => {
  const [lockedState, setLockedState] = useState(isLocked);
  const { module } = useModule();

  const handleStateChange = () => {
    Swal.fire({
      title: 'Jesteś pewien?',
      text: 'Czy chcesz zmienić stan zamknięcia miesiąca księgowego? Zamknięcie miesiąca uniemożliwia edycję lub dodawanie nowych dokumentów w danym miesiącu rozliczeniowym.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Tak, zmień to',
      cancelButtonText: 'Nie, zostaw bez zmian',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const newState = !lockedState;

          if (monthlySettlement) {
            await module.api.put({ isClosed: newState }, { id: monthlySettlement });
          } else {
            await module.api.post({ year: settlementYear, month: settlementMonth, isClosed: newState });
          }

          Swal.fire('Sukces', 'Stan został zaktualizowany.', 'success');
          setLockedState(newState);
          queryClient.invalidateQueries('accountancy-is-month-locked');
        } catch (error) {
          console.error('Błąd podczas aktualizacji stanu zamknięcia:', error);
          Swal.fire('Błąd', 'Wystąpił błąd podczas aktualizacji stanu zamknięcia. Spróbuj ponownie.', 'error');
        }
      }
    });
  };

  return (
    <div onClick={handleStateChange} style={{ cursor: 'pointer' }}>
      <CheckboxColumn active={lockedState} />
    </div>
  );
};

export default SettlementComponent;
