import { InvoiceModule } from '@Accountancy/Accountancy';
import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import InvoiceField from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceField';
import { Invoice } from '@Accountancy/Invoice/Types/invoice';
import { Col, Container } from '@Components/Theme/Grid';
import { OpenInNewOutlined } from '@mui/icons-material';
import { Alert, Button, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const InvoiceGeneralFieldsGroup: FC<InvoiceGroupProps> = props => {
  const exchangeDate = dayjs(props.values[props.fields.exchangeInfoDate.id] ?? dayjs().subtract(1, 'day')).format('YYYY-MM-DD');
  const sellDate = dayjs(props.values[props.fields.sellDate.id] ?? new Date()).format('YYYY-MM-DD');
  const correction: Invoice = props.values[props.fields.correction.id];
  const history = useHistory();

  const extractOriginalDocumentFromCorrection = (correction: Partial<Invoice>): Partial<Invoice> => {
    return correction.correction?.id ? extractOriginalDocumentFromCorrection(correction.correction) : correction;
  };

  const oryginalDocument = extractOriginalDocumentFromCorrection(correction);

  useEffect(() => {
    if (sellDate && dayjs(sellDate).subtract(1, 'day').format('Y-m-d') !== exchangeDate) {
      props.onUpdate(dayjs(sellDate).subtract(1, 'day'), props.fields.exchangeInfoDate.id);
    }
  }, [sellDate]);

  return (
    <Paper sx={{ minHeight: 180, paddingBottom: 3 }}>
      <Container>
        <Col xs={12}>
          <Typography variant="subtitle1" align="center">
            Korekta
          </Typography>
        </Col>
        <Col xs={12} md={6}>
          <Alert severity="info">
            <Typography variant="body2" align="center">
              Korekta do dokumentu
              <Button
                size="small"
                variant="contained"
                color="info"
                onClick={() => history.push(InvoiceModule.showUrl(oryginalDocument.id ?? ''))}
              >
                {oryginalDocument.number} <OpenInNewOutlined />
              </Button>{' '}
              z dnia {dayjs(oryginalDocument.issueDate).format('YYYY-MM-DD')}
            </Typography>
          </Alert>
        </Col>
        <Col xs={12} md={6}>
          <InvoiceField {...props} fieldKey={'reasonForCorrection'} />
        </Col>
      </Container>
    </Paper>
  );
};

export default InvoiceGeneralFieldsGroup;
