import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import Switch from 'components/Form/Switch';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

const curdRoles = (label: string, suffix: string, additional: any = []) => ({
  label,
  options: [
    { type: 'CREATE', value: `ROLE_CREATE_${suffix}` },
    { type: 'SHOW', value: `ROLE_SHOW_${suffix}` },
    { type: 'EDIT', value: `ROLE_EDIT_${suffix}` },
    { type: 'REMOVE', value: `ROLE_REMOVE_${suffix}` },
    ...additional,
  ],
});

const PERMISSIONS = [
  curdRoles('Pracownik', 'EMPLOYEE'),
  curdRoles('Role', 'ROLE'),
  curdRoles('Technologia', 'MANUFACTURE_TECHNOLOGY'),
  curdRoles('Magazyn', 'WAREHOUSE_WAREHOUSE'),
  curdRoles('Dokument magazynowy', 'WAREHOUSE_DOCUMENT', [
    {
      type: 'SKIP_VALIDATION',

      value: `ROLE_SKIP_VALIDATION_WAREHOUSE_DOCUMENT`,
    },
  ]),
  curdRoles('Zlecenie', 'MANUFACTURE_ERRAND'),
  curdRoles('Zadanie', 'TASK_MANAGEMENT_TASK'),
  curdRoles('Kontrahent', 'CONTRACTOR_CONTRACTOR'),
  curdRoles('Leady sprzedażowe', 'CONTRACTOR_LEAD'),
  curdRoles('Produkt', 'MANUFACTURE_PRODUCT'),
  {
    label: 'Słowniki',
    options: [
      { type: 'CREATE', value: 'ROLE_CREATE_CORE_DICTIONARY' },
      { type: 'EDIT', value: 'ROLE_EDIT_CORE_DICTIONARY' },
      { type: 'REMOVE', value: 'ROLE_REMOVE_CORE_DICTIONARY' },
    ],
  },
  {
    label: 'Logi',
    options: [{ type: 'SHOW', value: 'ROLE_SHOW_CORE_LOGS' }],
  },
  curdRoles('Konfiguracja', 'CONFIGURATION'),
  curdRoles('Stanowiska', 'MANUFACTURE_WORKPLACE'),
  curdRoles('Maszyny', 'MANUFACTURE_MACHINE'),
  curdRoles('Etykiety', 'MANUFACTURE_LABEL'),
  curdRoles('Zamówienia od klientów', 'ECOMMERCE_ORDER'),
  curdRoles('Zlecenia kompletacji', 'WAREHOUSE_ASSEMBLING_ORDER'),
  curdRoles('Wysyłka', 'WAREHOUSE_SHIPMENT'),
  curdRoles('Zlecenia na produkcję', 'MANUFACTURE_PRODUCTION_ORDER'),
  curdRoles('Zapotrzebowanie', 'MANUFACTURE_PRODUCTION_DEMAND'),
  curdRoles('Języki', 'CORE_LANGUAGE'),
  curdRoles('Slider sklepu', 'STORE_SLIDE'),
  curdRoles('Dostawy - Metody', 'DELIVERY_METHOD'),
  curdRoles('Dostawy - Cennik', 'DELIVERY_FEE'),
  curdRoles('Konfiguracja exportów', 'CORE_EXPORT_CONFIG'),
  curdRoles('Pola dodatkowe - zdjęcia', 'CUSTOM_FIELD_IMAGE'),
  curdRoles('Faktury', 'ACCOUNTANCY_INVOICE'),
  curdRoles('Rozliczenia', 'ACCOUNTANCY_SETTLEMENT'),
  curdRoles('Zam. do dostawców', 'WAREHOUSE_PROCUREMENT_ORDER', [
    {
      type: 'NOTIFICATION_SHOW',
      value: `ROLE_SHOW_WAREHOUSE_PROCUREMENT_NOTIFICATION`,
    },
  ]),
];

const PermissionPicker: CustomFieldComponentType<false, string[]> = ({ field, value = [], onChange, readonly }) => {
  const { t } = useTranslation();

  const isPermissionChecked = (name: string) => {
    return (value ?? []).includes(name);
  };

  const isGroupChecked = permissions => {
    return permissions.filter(el => isPermissionChecked(el.value)).length === permissions.length;
  };

  const toggleGroup = permissions => {
    if (isGroupChecked(permissions)) {
      const valuesToExclude = permissions.map(el => el.value).filter(val => isPermissionChecked(val));
      onChange([...(value ?? []).filter(el => !valuesToExclude.includes(el))], field.id);
    } else {
      const valuesToInclude = permissions.map(el => el.value).filter(val => !isPermissionChecked(val));
      onChange([...(value ?? []), ...valuesToInclude], field.id);
    }
  };

  const togglePermission = name => {
    onChange(isPermissionChecked(name) ? (value ?? []).filter(el => el !== name) : [...(value ?? []), name], field.id);
  };

  return (
    <>
      <Col xs={12}>
        <Row>
          {PERMISSIONS.map((permissionGroup, permissionGroupIndex) => (
            <Col key={`permission_group_${permissionGroupIndex}`} style={{ minWidth: '280px', maxWidth: '360px' }}>
              <Card className="w-100">
                <CardHeader className="bg-gray">
                  <Switch
                    size={{ md: 12 }}
                    disabled={readonly}
                    label={permissionGroup.label}
                    value={isGroupChecked(permissionGroup.options)}
                    name="permissionGroup"
                    onChange={() => {
                      toggleGroup(permissionGroup.options);
                      return false;
                    }}
                  />
                </CardHeader>
                <CardBody>
                  {permissionGroup.options.map((permission, permissionIndex) => (
                    <Switch
                      className="mb-2"
                      key={`permission_${permissionIndex}`}
                      size={{ md: 12 }}
                      disabled={readonly}
                      label={t(`permissions.common.type.${permission.type}`)}
                      value={isPermissionChecked(permission.value)}
                      name="permission"
                      onChange={() => {
                        togglePermission(permission.value);
                        return false;
                      }}
                    />
                  ))}
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
    </>
  );
};

export default PermissionPicker;
