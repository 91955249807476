import Department, { Department as DepartmentModel } from '@Accountancy/Department/Department';
import Invoice from '@Accountancy/Invoice/Invoice';
import { Invoice as InvoiceModel } from '@Accountancy/Invoice/Types/invoice';
import MonthlySettlement, { MonthlySettlementReport } from '@Accountancy/Settlement/MonthlySettlement';
import CRUDModule from '@Core/CRUDModule';

export const InvoiceModule = new CRUDModule<InvoiceModel>(Invoice);
export const DepartmentModule = new CRUDModule<DepartmentModel>(Department);
export const MonthlySettlementModule = new CRUDModule<MonthlySettlementReport>(MonthlySettlement);

export default {
  [Invoice.urlPrefix]: InvoiceModule,
  [Department.urlPrefix]: DepartmentModule,
  [MonthlySettlement.urlPrefix]: MonthlySettlementModule,
};
