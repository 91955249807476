import swal from 'sweetalert';

export const confirmRemove = (
  removeAction,
  title = 'Czy jesteś pewien?',
  text = 'Zamierzasz skasować rekord, tej akcji nie można cofnąć!',
  showSuccess = true,
  successMessage = 'Rekord został skasowany',
) => {
  return swal({
    title: title,
    text: text,
    icon: 'warning',
    dangerMode: true,
    buttons: {
      cancel: {
        text: 'Anuluj',
        value: null,
        visible: true,
      },
      confirm: {
        text: 'Tak, jestem pewien',
        visible: true,
        value: true,
        closeModal: false,
      },
    },
  })
    .then(value => {
      return new Promise((res, rej) => {
        if (value === true) {
          removeAction()
            .then(() => {
              res(value);
            })
            .catch(rej);
        }
      });
    })
    .then(value => {
      if (value) {
        if (showSuccess) {
          swal({
            title: successMessage,
            icon: 'success',
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      }
    })
    .catch(err => {
      if (err) {
        swal('O nie!', err?.response?.data?.['hydra:description'] ?? `Wystąpił problem ze skasowaniem rekordu!`, 'error');
      } else {
        swal.stopLoading();
        swal.close();
      }
    });
};

export const confirmAction = (
  action,
  title = 'Czy jesteś pewien?',
  text = 'Tej akcji nie można cofnąć!',
  showSuccess = true,
  successMessage = 'Akcja została wykonana prawidłowo!',
  dangerMode = true,
) => {
  return swal({
    title: title,
    text: text,
    icon: 'warning',
    dangerMode: dangerMode,
    buttons: {
      cancel: {
        text: 'Anuluj',
        value: null,
        visible: true,
      },
      confirm: {
        text: 'Tak, jestem pewien',
        visible: true,
        value: true,
        color: 'green',
        closeModal: false,
      },
    },
  })
    .then(value => {
      return new Promise((res, rej) => {
        if (value === true) {
          action()
            .then(() => {
              res(value);
            })
            .catch(rej);
        }
      });
    })
    .then(value => {
      if (value) {
        if (showSuccess) {
          swal({
            title: successMessage,
            icon: 'success',
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      }
    })
    .catch(err => {
      if (err) {
        let message = '';

        if (err.response && err.response.data) {
          message = err.response.data['hydra:description'] || err.response.data['hydra:title'] || '';
        }
        message += '\nWystąpił problem z wykonaniem akcji!';

        swal('Wystąpił błąd!', message, 'error');
      } else {
        swal.stopLoading();
        swal.close();
      }
    });
};

export default { confirmRemove, confirmAction };
