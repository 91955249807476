import useFormError from './useFormError';
import LabelerId from '@Components/CustomFields/LabelerId';
import { ClearButton } from '@Components/DataGrid/Filter/ClearButton';
import { HighlightOff } from '@mui/icons-material';
import { Paper, Table, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import classnames from 'classnames';
import BaseInput from 'components/Form/BaseInput';
import _ from 'lodash';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, FormFeedback, FormGroup, Label, Modal, ModalBody, Row } from 'reactstrap';

const IndeterminateCheckbox = forwardRef((props: any, ref) => {
  // @ts-ignore
  const { indeterminate, ...rest } = props;
  const defaultRef = useRef<HTMLInputElement>();
  const resolvedRef = (ref || defaultRef) as typeof defaultRef;

  useEffect(() => {
    // @ts-ignore
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  // @ts-ignore
  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});
IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

export type ListPickerProps<T> = {
  className?: string;
  options: T[];
  name: string;
  placeholder?: string;
  helpText?: string;
  label?: string;
  size?: { md: number };
  optionTrackBy?: keyof T | ((el: T) => string);
  optionLabel?: keyof T | ((el: T) => string | JSX.Element);
  disabled?: boolean;
  tabIndex?: number;
  inputProps?: any;
  errorKey?: string | null;
  modalStyles?: any;
  enableSorting?: boolean;
  removeWrapperClass?: boolean;
  labelerId?: number;
  isClearable?: boolean;
} & (
  | { multiple?: true; value: any[]; onChange?: (value: any[], name: string, option: any[]) => void }
  | { multiple?: false; value: any; onChange?: (value: any, name: string, option: any) => void }
);
function ListPicker<T = any>(props: ListPickerProps<T>) {
  const {
    className = 'mb-3',
    value,
    options = [],
    name,
    placeholder,
    helpText,
    label = null,
    size = { md: 12 },
    onChange = () => undefined,
    optionTrackBy = '@id',
    optionLabel = 'name',
    disabled,
    tabIndex,
    inputProps = {},
    errorKey,
    modalStyles,
    enableSorting = false,
    labelerId,
    multiple = false,
    removeWrapperClass = true,
    isClearable = false,
  } = props;

  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<typeof value>(multiple ? [] : null);
  const [selectedValue, setSelectedValue] = useState<typeof value>(multiple ? [] : null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<string>('');
  const [hasErrors, errorMessage] = useFormError(errorKey ?? '');

  const mapToValue = (el: any): string => {
    if (typeof el === 'string' || el === null || el === undefined) {
      return el;
    }

    return typeof optionTrackBy === 'function' ? optionTrackBy(el) : el?.[optionTrackBy] ?? el;
  };

  const mapToLabel = el => {
    if (!el) {
      return null;
    }

    if (typeof el === 'string' || typeof el === 'number' || el === null || el === undefined) {
      return el;
    }

    return typeof optionLabel === 'function' ? optionLabel(el) : el?.[optionLabel] ?? `Error: ${el}`;
  };

  const resolveDefaultOptions = () => {
    if (value === null) {
      setSelectedOption(multiple ? [] : null);
      setInitialized(true);
      return;
    }

    if (!initialized) {
      if (multiple) {
        const defaultValues = mapToValue(value);
        setSelectedOption(options.filter(el => defaultValues.includes(mapToValue(el))));
      } else {
        setSelectedOption(options.find(el => mapToValue(el) === mapToValue(value)) ?? null);
      }
      setInitialized(true);
    }
  };

  useEffect(() => {
    resolveDefaultOptions();
  }, [value, initialized]);

  useEffect(() => {
    setFilter('');
  }, [open]);

  useEffect(() => {
    if (initialized) {
      if (multiple) {
        setSelectedValue(selectedOption.map(mapToValue));
      } else {
        setSelectedValue(mapToValue(selectedOption));
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (multiple) {
      if (!_.isEqual(selectedValue.map(mapToValue), (Array.isArray(value) ? value : [value]).map(mapToValue))) {
        resolveDefaultOptions();
      }
    } else {
      if (!_.isEqual(mapToValue(selectedValue), mapToValue(value))) {
        resolveDefaultOptions();
      }
    }
  }, [JSON.stringify(value)]);

  useEffect(() => {
    if (!initialized) {
      return;
    }
    if (multiple) {
      if (!_.isEqual(selectedValue.map(mapToValue), (Array.isArray(value) ? value : [value]).map(mapToValue))) {
        onChange(selectedValue, name, selectedOption);
      }
    } else {
      if (!_.isEqual(mapToValue(selectedValue), mapToValue(value))) {
        onChange(selectedValue, name, selectedOption);
      }
    }
  }, [selectedValue]);

  const id = () => {
    return ((selectedOption?.[0] ?? selectedOption)?.['@id'] ?? '').split('/').pop();
  };

  const resolveOptions = () => {
    let filteredOptions = options ?? [];

    if (filter && filter !== '') {
      filteredOptions = filteredOptions.filter(el => {
        const mappedVal = mapToLabel(el);
        if (typeof mappedVal === 'string') {
          return mappedVal.toLowerCase().includes(filter.toLowerCase());
        }

        return true;
      });
    }

    if (!enableSorting) {
      return filteredOptions;
    }

    return filteredOptions.sort((a, b) => {
      const mappedValA = mapToLabel(a);
      const mappedValB = mapToLabel(b);
      const nameA = typeof mappedValA === 'string' ? mappedValA?.toLowerCase() : mappedValA; // ignore upper and lowercase
      const nameB = typeof mappedValB === 'string' ? mappedValB?.toLowerCase() : mappedValB; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  };

  const toggleOption = toggledOption => {
    const val = mapToValue(toggledOption);

    if (!selectedValue.includes(val)) {
      setSelectedOption([...selectedOption, toggledOption]);
    } else {
      setSelectedOption(selectedOption.filter(el => mapToValue(el) !== val));
    }
  };

  return (
    <>
      <Tooltip
        title={(Array.isArray(selectedOption) ? selectedOption : [selectedOption]).map(mapToLabel).join(', ')}
        arrow={true}
        leaveDelay={400}
        enterDelay={150}
      >
        <div className={classnames({ 'record-reader': id().length > 0 })} style={{ cursor: 'pointer' }}>
          {mapToLabel(selectedOption)?.$$typeof && (
            <Col {...size} className={classnames({ 'disable-group-margin': true })} onClick={() => setOpen(true)}>
              <Row>
                <FormGroup className={classnames({ 'disable-group-margin': true })}>
                  {label && label !== '' && (
                    <Label>
                      {typeof labelerId !== 'undefined' && <LabelerId labelerId={labelerId} />}
                      {t(label)}
                    </Label>
                  )}
                  {multiple && (
                    <>
                      {selectedOption.length === 0 && (
                        <div className={classnames('form-control', { 'is-invalid': hasErrors })}>{placeholder}</div>
                      )}
                      {selectedOption.length === 1 && (
                        <div className={classnames('', { 'form-control': !removeWrapperClass, 'is-invalid': hasErrors })}>
                          {mapToLabel(selectedOption[0])}
                        </div>
                      )}
                      {selectedOption.length > 1 && (
                        <div className={classnames('form-control', { 'is-invalid': hasErrors })}>
                          <Label style={{ backgroundColor: '#546de4', color: '#fff', margin: 0, padding: '4px 4px', borderRadius: 10 }}>
                            Wybrano {selectedOption.length} wartości
                          </Label>
                        </div>
                      )}
                    </>
                  )}
                  {!multiple && (
                    <>
                      <div className={classnames('', { 'form-control': !removeWrapperClass, 'is-invalid': hasErrors })}>
                        {mapToLabel(selectedOption)}
                      </div>
                    </>
                  )}
                  {hasErrors && <FormFeedback>{errorMessage}</FormFeedback>}
                </FormGroup>
              </Row>
            </Col>
          )}
          {!mapToLabel(selectedOption?.[0] ?? selectedOption)?.$$typeof && (
            <BaseInput
              onClick={() => setOpen(true)}
              label={label ?? undefined}
              value={multiple ? selectedOption.map(mapToLabel).join(', ') : mapToLabel(selectedOption)}
              name={name}
              type={'text'}
              onChange={() => false}
              inputProps={{
                ...(inputProps ?? {}),
                tabIndex: tabIndex,
                placeholder: placeholder ?? label,
              }}
              disabled={disabled}
              errorKey={errorKey ?? undefined}
              className={className}
              disableGroupMargin={true}
              helpText={helpText}
              labelerId={labelerId}
            />
          )}
          {isClearable && (
            <>
              <ClearButton
                clear={() => {
                  setSelectedOption(multiple ? [] : null);
                  setSelectedValue(multiple ? [] : null);
                }}
                isFilter={Boolean(multiple ? selectedValue?.length : selectedValue)}
              />
            </>
          )}
        </div>
      </Tooltip>
      <Modal
        isOpen={open && !disabled}
        centered={true}
        size={'sm'}
        backdrop={true}
        toggle={() => setOpen(!open)}
        style={{ maxWidth: 'calc(580px - 10vw)', minWidth: '320px', maxHeight: '90vw', ...(modalStyles ?? {}) }}
        className="list-selector-modal"
      >
        <ModalBody className="py-3 px-5">
          {open && (
            <>
              <div className="modal-header-nav-bar">
                <div className={'modal-header-title'}>{label ?? 'Wybierz nową wartość'}</div>
                <div>
                  <div className={'modal-header-close'}>
                    <HighlightOff onClick={() => setOpen(false)} />
                  </div>
                </div>
              </div>
            </>
          )}
          <Paper variant="outlined" style={{ padding: 12, background: '#fdfdfd', color: '#303030' }}>
            <input
              type="text"
              value={filter}
              placeholder="Filtruj..."
              onChange={({ target: { value } }) => setFilter(value)}
              className="transparent-input w-100"
            />
          </Paper>
          <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
            <Table className="table-picker">
              <TableBody>
                {resolveOptions().map((el, index) => (
                  <TableRow key={index}>
                    {multiple && (
                      <TableCell>
                        <IndeterminateCheckbox checked={selectedValue?.includes(mapToValue(el))} onClick={() => toggleOption(el)} />
                      </TableCell>
                    )}
                    <TableCell>{mapToLabel(el)}</TableCell>
                    {!multiple && (
                      <TableCell className="picker">
                        <Button
                          onClick={() => {
                            setSelectedOption(el);
                            setOpen(false);
                          }}
                          className="btn btn-info btn-sm"
                        >
                          <i className="mdi mdi-cursor-pointer" style={{ padding: '0 20px' }} />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ListPicker;
