import useIsGranted from 'common/hooks/isGranted';
import { useModule } from 'context/ModuleContext';

export default function useModuleEditRecordURL(id: string | number | null | undefined): string {
  const { module } = useModule();
  const isGranted = useIsGranted(module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`);

  if (!id || !module.configuration.form || module.configuration.form.disableEditRecord || !isGranted) {
    return '';
  }

  return module.editUrl(id);
}
