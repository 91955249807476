import { MonthlySettlementModule } from '@Accountancy/Accountancy';
import { get } from 'helpers/Axios';
import { useQuery } from 'react-query';

function useIsMonthLocked(year: number, month: number): boolean {
  const { data, isLoading, isLoadingError } = useQuery(
    ['accountancy-is-month-locked', `accountancy-is-month-locked-${year}-${month}`],
    () =>
      get(MonthlySettlementModule.configuration.api.collection.get, {
        params: {
          year,
          month,
          ['monthlySettlement.isClosed']: true,
        },
      }),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: 'always',
      refetchInterval: false,
    },
  );

  return isLoading || isLoadingError || data?.['hydra:totalItems'] > 0;
}

export default useIsMonthLocked;
