import { useModule } from '../../../../../context/ModuleContext';
import { sumPositions } from '@Accountancy/Invoice/Invoice';
import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import { computeNewPositionState } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoicePositionsGroup';
import { Invoice, InvoicePosition } from '@Accountancy/Invoice/Types/invoice';
import useIsMonthLocked from '@Accountancy/Settlement/Hooks/useIsMonthLocked';
import NumberInput from '@Components/Form/MUI/NumberInput';
import TextInput from '@Components/Form/MUI/TextInput';
import useFormError from '@Components/Form/useFormError';
import ModuleListPicker from '@Components/Module/ModuleListPicker';
import { Col, Container } from '@Components/Theme/Grid';
import TaxesMenu from '@Core/Accountancy/Components/TaxesMenu';
import { Product } from '@Manufacture/Product';
import { DeleteForeverOutlined, VerticalAlignBottomOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {
  Alert,
  Box,
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { FC, Fragment } from 'react';

type PositionsRowProps = InvoiceGroupProps & {
  position: InvoicePosition;
  index: number;
  correct: boolean;
  readonly: boolean;
  onRowRemove: (index: number) => void;
  onRowUpdate: (index: number, position: InvoicePosition) => void;
};

const FooterCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.9rem',
}));
const PlaceholderCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
}));

const CorrectPositionsRow: FC<PositionsRowProps> = props => {
  const currency: string = props.values?.[props.fields.currency.id] ?? [];
  const exchangeCurrency: string = props.values?.[props.fields.exchangeCurrency.id] ?? [];

  const handleInputChange = (value, name: keyof InvoicePosition) => {
    props.onRowUpdate(props.index, computeNewPositionState(value, name, props.position));
  };

  const handleProductSelect = (row: null | Product) => {
    if (!row) {
      props.onRowUpdate(props.index, { ...props.position, product: null, name: '' });
      return;
    }

    props.onRowUpdate(props.index, {
      ...props.position,
      product: row,
      name: row.name ?? '',
      unit: row.unit,
      code: row.symbol,
      ean: row.ean,
    });
  };

  return (
    <>
      <TableRow sx={!props.correct ? { backgroundColor: '#eff2f7' } : {}}>
        {!props.readonly && (
          <TableCell sx={{ width: 40, textAlign: 'center' }}>
            <DeleteForeverOutlined color="error" cursor="pointer" onClick={() => props.onRowRemove(props.index)} />
          </TableCell>
        )}
        {props.readonly && <TableCell sx={{ width: 40, textAlign: 'center' }}></TableCell>}
        <TableCell sx={{ width: 120, textAlign: 'center' }}>
          {props.position.seq}. {props.correct ? 'Po korekcie' : 'Przed korektą'}
        </TableCell>
        <TableCell>
          <TextInput
            disabled={props.readonly}
            value={props.position.name}
            onChange={val => handleInputChange(val, 'name')}
            inputProps={{
              endAdornment: !props.readonly && (
                <InputAdornment position="end">
                  <ModuleListPicker<Product>
                    disabled={props.readonly}
                    moduleName={'manufacture-products'}
                    onChange={handleProductSelect}
                    readerValue={null}
                  >
                    <ListAltIcon color="primary" cursor="pointer" />
                  </ModuleListPicker>
                </InputAdornment>
              ),
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 190 }}>
          <TextInput disabled={props.readonly} value={props.position.code} onChange={val => handleInputChange(val, 'code')} />
        </TableCell>
        <TableCell sx={{ width: 190 }}>
          <TextInput disabled={props.readonly} value={props.position.ean} onChange={val => handleInputChange(val, 'ean')} />
        </TableCell>
        <TableCell sx={{ width: 80 }}>
          <NumberInput
            disabled={props.readonly}
            value={props.position.quantity}
            onChange={val => handleInputChange(val, 'quantity')}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ModuleListPicker
                    disabled={props.readonly}
                    moduleName={'manufacture-units'}
                    onChange={val => handleInputChange(val, 'unit')}
                    readerValue={props.position.unit}
                  >
                    {props.position.unit?.name ?? 'Szt.'}
                  </ModuleListPicker>
                </InputAdornment>
              ),
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 170 }}>
          <NumberInput
            disabled={props.readonly}
            value={props.position.priceUnitNet}
            onChange={val => handleInputChange(val, 'priceUnitNet')}
            inputProps={{
              endAdornment: <InputAdornment position="end">{props.values[props.fields.currency.id]}</InputAdornment>,
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 100 }}>
          <TaxesMenu
            value={props.position.tax?.['@id'] ?? null}
            onChange={val => handleInputChange(val.data, 'tax')}
            disabled={props.readonly}
          />
        </TableCell>
        <TableCell sx={{ width: 170 }}>
          <NumberInput
            disabled={props.readonly}
            value={props.position.priceTotalNet}
            onChange={val => handleInputChange(val, 'priceTotalNet')}
            inputProps={{
              endAdornment: <InputAdornment position="end">{props.values[props.fields.currency.id]}</InputAdornment>,
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 170 }}>
          <NumberInput
            disabled={props.readonly}
            value={props.position.priceTotalGross}
            onChange={val => handleInputChange(val, 'priceTotalGross')}
            inputProps={{
              endAdornment: <InputAdornment position="end">{props.values[props.fields.currency.id]}</InputAdornment>,
            }}
          />
        </TableCell>
      </TableRow>
      {exchangeCurrency !== currency && (
        <TableRow sx={!props.correct ? { backgroundColor: '#eff2f7' } : {}}>
          <TableCell sx={{ width: 40, textAlign: 'center' }}></TableCell>
          <TableCell sx={{ width: 40, textAlign: 'center' }}></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell sx={{ width: 80 }}></TableCell>
          <TableCell sx={{ width: 170 }}>
            <NumberInput
              disabled={true}
              value={parseFloat((props.position.priceUnitNet * props.values[props.fields.exchangeRate.id]).toFixed(2))}
              onChange={val => handleInputChange(val, 'priceUnitNet')}
              inputProps={{
                endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
              }}
            />
          </TableCell>
          <TableCell sx={{ width: 100 }}></TableCell>
          <TableCell sx={{ width: 170 }}>
            <NumberInput
              disabled={true}
              value={parseFloat((props.position.priceTotalNet * props.values[props.fields.exchangeRate.id]).toFixed(2))}
              onChange={val => handleInputChange(val, 'priceTotalNet')}
              inputProps={{
                endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
              }}
            />
          </TableCell>
          <TableCell sx={{ width: 170 }}>
            <NumberInput
              disabled={true}
              value={parseFloat((props.position.priceTotalGross * props.values[props.fields.exchangeRate.id]).toFixed(2))}
              onChange={val => handleInputChange(val, 'priceTotalGross')}
              inputProps={{
                endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
              }}
            />
          </TableCell>
        </TableRow>
      )}
      {props.correct && (
        <TableRow>
          <TableCell colSpan={10}>&nbsp;</TableCell>
        </TableRow>
      )}
    </>
  );
};

const CorrectPositionsGroup: FC<InvoiceGroupProps & { hideLabel?: boolean }> = props => {
  const positions: InvoicePosition[] = Object.values(props.values?.[props.fields.positions.id] ?? []);
  const currency: string = props.values?.[props.fields.currency.id] ?? [];
  const corrected: Invoice = props.values?.[props.fields.correction.id] ?? [];
  const exchangeCurrency: string = props.values?.[props.fields.exchangeCurrency.id] ?? [];
  const [hasErrors, errorMessage] = useFormError('positions');
  const { module } = useModule();
  const issueDate = dayjs(props.values[props.fields.issueDate.id]);
  const isMonthLocked = useIsMonthLocked(issueDate.year(), issueDate.month() + 1);

  const addNewRow = () => {
    props.onUpdate(
      [...positions, { quantity: 1, tax: positions?.[0]?.tax ?? null, seq: Math.max(1, ...positions.map(el => el.seq)) + 1 }],
      props.fields.positions.id,
    );
  };

  const handeRemove = (index: number) => {
    props.onUpdate(
      positions.filter((_, i) => i !== index).map((el, index) => ({ ...el, seq: index + 1 })),
      props.fields.positions.id,
    );
  };
  const handleUpdate = (index: number, position: InvoicePosition) => {
    props.onUpdate(
      positions.map((el, i) => (i === index ? position : el)),
      props.fields.positions.id,
    );
  };

  const readonly: boolean =
    isMonthLocked ||
    props.readonly ||
    (module.configuration.form?.forceReadonlyField?.(props.fields.positions, props.values, Object.values(props.fields), '') ?? false);

  return (
    <Paper>
      <Container>
        {!props.hideLabel && (
          <Col xs={12}>
            <Typography variant="subtitle1" align="center">
              Pozycje
            </Typography>
            {hasErrors && <Alert severity="error">{errorMessage}</Alert>}
          </Col>
        )}
        <Col xs={12} sx={{ padding: 2 }}>
          <Table padding="none">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 40, textAlign: 'center' }}></TableCell>
                <TableCell sx={{ width: 120, textAlign: 'center' }}>lp.</TableCell>
                <TableCell>Nazwa</TableCell>
                <TableCell>Symbol</TableCell>
                <TableCell>EAN</TableCell>
                <TableCell sx={{ width: 150 }}>Ilość</TableCell>
                <TableCell sx={{ width: 170 }}>Cenna netto</TableCell>
                <TableCell sx={{ width: 100 }}>
                  <TaxesMenu
                    value={null}
                    onChange={val =>
                      props.onUpdate(
                        positions.map(el => computeNewPositionState(val.data, 'tax', el)),
                        props.fields.positions.id,
                      )
                    }
                    disabled={readonly}
                  >
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                      Vat <VerticalAlignBottomOutlined />
                    </Box>
                  </TaxesMenu>
                </TableCell>
                <TableCell sx={{ width: 170 }}>Wartość netto</TableCell>
                <TableCell sx={{ width: 170 }}>Wartość brutto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {positions.map((el, index) => (
                <Fragment key={index}>
                  {corrected.positions?.[index] && (
                    <CorrectPositionsRow
                      key={`correct-${index}`}
                      {...props}
                      readonly={true}
                      position={corrected.positions?.[index]}
                      index={index}
                      correct={false}
                      onRowRemove={handeRemove}
                      onRowUpdate={handleUpdate}
                    />
                  )}
                  <CorrectPositionsRow
                    key={`corrected-${index}`}
                    {...props}
                    readonly={readonly}
                    position={el}
                    index={index}
                    correct={true}
                    onRowRemove={handeRemove}
                    onRowUpdate={handleUpdate}
                  />
                </Fragment>
              ))}
            </TableBody>
            {!readonly && (
              <TableFooter>
                <TableRow>
                  <PlaceholderCell></PlaceholderCell>
                  <PlaceholderCell></PlaceholderCell>
                  <TableCell>
                    <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => addNewRow()} fullWidth={true}>
                      Dodaj pozycję
                    </Button>
                  </TableCell>
                  <PlaceholderCell colSpan={7}></PlaceholderCell>
                </TableRow>
                <TableRow>
                  <PlaceholderCell colSpan={10}></PlaceholderCell>
                </TableRow>
                <TableRow>
                  <PlaceholderCell colSpan={7}></PlaceholderCell>
                  <FooterCell>Suma Netto</FooterCell>
                  <FooterCell sx={{ textAlign: 'right', paddingRight: 1 }}>
                    {sumPositions(positions, 'priceTotalNet').toFixed(2)} {currency !== exchangeCurrency && `${currency}`}
                  </FooterCell>
                  <FooterCell sx={{ textAlign: currency == exchangeCurrency ? 'left' : 'right' }}>
                    {currency == exchangeCurrency && `${currency}`}
                    {currency !== exchangeCurrency &&
                      `${(sumPositions(positions, 'priceTotalNet') * props.values[props.fields.exchangeRate.id]).toFixed(
                        2,
                      )} ${exchangeCurrency}`}
                  </FooterCell>
                </TableRow>
                <TableRow>
                  <PlaceholderCell colSpan={7}></PlaceholderCell>
                  <FooterCell>Suma VAT</FooterCell>
                  <FooterCell sx={{ textAlign: 'right', paddingRight: 1 }}>
                    {(sumPositions(positions, 'priceTotalGross') - sumPositions(positions, 'priceTotalNet')).toFixed(2)}{' '}
                    {currency !== exchangeCurrency && `${currency}`}
                  </FooterCell>
                  <FooterCell sx={{ textAlign: currency == exchangeCurrency ? 'left' : 'right' }}>
                    {currency == exchangeCurrency && `${currency}`}
                    {currency !== exchangeCurrency &&
                      `${(
                        (sumPositions(positions, 'priceTotalGross') - sumPositions(positions, 'priceTotalNet')) *
                        props.values[props.fields.exchangeRate.id]
                      ).toFixed(2)} ${exchangeCurrency}`}
                  </FooterCell>
                </TableRow>
                <TableRow>
                  <PlaceholderCell colSpan={7}></PlaceholderCell>
                  <FooterCell>Suma Brutto</FooterCell>
                  <FooterCell sx={{ textAlign: 'right', paddingRight: 1 }}>
                    {sumPositions(positions, 'priceTotalGross').toFixed(2)} {currency !== exchangeCurrency && `${currency}`}
                  </FooterCell>
                  <FooterCell sx={{ textAlign: currency == exchangeCurrency ? 'left' : 'right' }}>
                    {currency == exchangeCurrency && `${currency}`}
                    {currency !== exchangeCurrency &&
                      `${(sumPositions(positions, 'priceTotalGross') * props.values[props.fields.exchangeRate.id]).toFixed(
                        2,
                      )} ${exchangeCurrency}`}
                  </FooterCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </Col>
      </Container>
    </Paper>
  );
};

export default CorrectPositionsGroup;
