import HistoryModuleButton from '../History/HistoryModuleButton';
import Loader from '@Components/Theme/Common/Loader';
import CRUDModule from '@Core/CRUDModule';
import useFetchModuleConfig from '@Core/Hooks/useFetchModuleConfig';
import { CTMStrictRecord, ContextAction } from '@Core/Types/CTMModule';
import useCurrentUser from '@Employee/Employee/Hooks/UseCurrentUser';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, ListItemIcon, MenuList, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ApiForm, { ApiBaseFormHandle, BaseFromProps } from 'components/Theme/Common/ApiForm';
import SecuredView from 'components/Theme/Common/SecuredView';
import { ModuleProvider, useModule, useModuleConfig } from 'context/ModuleContext';
import { useModuleContext } from 'context/ModulesContext';
import { FC, Fragment, isValidElement, memo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { addSingleToast } from 'store/Toast/actions';

const GenericActionsComponent = ({ actions }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const doAction = (action: ContextAction) => {
    setAnchorEl(null);
    action.action();
  };

  return (
    <>
      <IconButton aria-label="more" aria-controls="context-menu" aria-haspopup="true" onClick={handleClick} color={'info'}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList>
          {actions.map((action, index) => (
            <MenuItem onClick={() => doAction(action)} key={index}>
              <ListItemIcon>{action.icon}</ListItemIcon>
              <Typography variant="inherit">{action.label}</Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

const ContextActionsComponent = ({ readonly, row }) => {
  const { id } = row;
  const { module } = useModule();
  const moduleConfig = useModuleConfig();
  const EditWrapper = moduleConfig.editButtonWrapper ?? Fragment;
  const editWrapperProps = typeof moduleConfig.editButtonWrapper === 'undefined' ? {} : { row };
  const location = useLocation();

  return (
    <>
      {id && <HistoryModuleButton id={id} module={module} />}
      {!moduleConfig.form?.disableEditRecord && readonly && id && (
        <EditWrapper {...editWrapperProps}>
          <SecuredView role={moduleConfig.roleEdit ?? `ROLE_EDIT_${moduleConfig.role}`}>
            <Link to={module.editUrl(id) + location.search} className="btn btn-primary ml-1">
              <i className="mdi mdi-pencil" /> Edycja
            </Link>
          </SecuredView>
        </EditWrapper>
      )}
      {!readonly && id && (
        <SecuredView role={`ROLE_SHOW_${module.configuration.role}`}>
          <Link to={module.showUrl(id) + location.search} className="btn btn-primary ml-1">
            <i className="mdi mdi-eye" /> Podgląd
          </Link>
        </SecuredView>
      )}
    </>
  );
};

const CustomContextActionsComponent = ({ readonly, id, record, ApiFormComponent }) => {
  const { module } = useModule();
  const useActions = module.configuration.form?.useContextActions ?? (() => []);
  const newActions = useActions({ readonly, id, record });

  if (module.configuration.form?.contextActions) {
    return (
      <>
        {module.configuration.form.contextActions({
          readonly,
          record,
          id,
          module,
          ApiFormComponent,
        })}
      </>
    );
  }

  if (isValidElement(newActions) || !newActions) {
    return newActions;
  }

  if ('length' in newActions) {
    return <GenericActionsComponent actions={newActions} />;
  }

  return null;
};

const CustomSubmitActionsComponent = ({ readonly, id, record, ApiFormComponent }) => {
  const { module } = useModule();
  return (
    <>
      {module.configuration.form?.submitActions &&
        module.configuration.form.submitActions({
          readonly,
          record,
          id,
          module,
          ApiFormComponent,
        })}
    </>
  );
};

export const ContextActions = memo(ContextActionsComponent);
export const CustomContextActions = memo(CustomContextActionsComponent);
export const CustomSubmitActions = memo(CustomSubmitActionsComponent);

export type ModuleFormProps<T extends CTMStrictRecord> = {
  module: CRUDModule<T>;
  id: string | null;
  afterSave?: (res: any, method: 'POST' | 'PUT', data: any) => void;
  onError?: (err: any) => void;
  showContextActions?: boolean;
  showBackButton?: boolean;
  readonly?: boolean;
  showConfigurationSwitcher?: boolean;
  overrideFormProps?: Partial<Omit<BaseFromProps, 'moduleId'>>;
};

const ModuleForm: FC<ModuleFormProps<any>> = props => {
  const {
    id,
    afterSave,
    onError,
    showContextActions = true,
    showBackButton = true,
    readonly = true,
    showConfigurationSwitcher = true,
    overrideFormProps = {},
  } = props;
  const { module } = useModule();
  const queryParams = Array.from(new URLSearchParams(window.location.search).entries()).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});
  const dispatch = useDispatch();
  const ApiFormComponent = useRef<ApiBaseFormHandle>(null);
  const currentUser = useCurrentUser();
  const { isLoading: isLoadingModuleConfig, data: moduleConfig } = useFetchModuleConfig(module.configuration.id);

  const onSubmit = (record, allFields): Promise<boolean> => {
    if (record.hasOwnProperty('@form')) {
      delete record['@form'];
    }
    const data = module.configuration.form?.prepareRecordToSave ? module.configuration.form.prepareRecordToSave(record, allFields) : record;

    return new Promise(res => {
      if (id) {
        module.api
          .put(data, { id })
          .then(res => {
            dispatch(addSingleToast({ title: `Zapisano zmiany`, config: { appearance: 'success' } }));
            if (typeof afterSave === 'function') {
              afterSave(res, 'PUT', data);
            }
          })
          .catch(err => {
            if (typeof onError === 'function') {
              onError?.(err);
            } else {
              throw err;
            }
            res(false);
          })
          .finally(() => res(true));
      } else {
        module.api
          .post(data)
          .then(res => {
            dispatch(
              addSingleToast({
                title: `Dodano ${module.configuration.name}`,
                config: { appearance: 'success' },
              }),
            );
            if (typeof afterSave === 'function') {
              afterSave?.(res, 'POST', data);
            }
          })
          .catch(err => {
            if (typeof onError === 'function') {
              onError?.(err);
            } else {
              throw err;
            }
            res(false);
          })
          .finally(() => res(true));
      }
    });
  };

  const fetch = async allFields => {
    if (!id) {
      const defaultRecord =
        (await module.configuration.form?.defaultRecord?.(allFields, currentUser, queryParams, moduleConfig?.configuration)) ?? {};
      const allFieldsRaw = allFields
        .filter(el => el.system)
        .reduce((acc, field) => {
          acc[field.propertyPath] = field.id;
          return acc;
        }, {});

      return {
        ...defaultRecord,
        '@formValues': {
          ...Object.keys(allFieldsRaw).reduce((acc, key) => {
            if (defaultRecord[key] !== undefined) {
              acc[allFieldsRaw[key]] = defaultRecord[key];
            }
            return acc;
          }, {}),
          ...defaultRecord['@formValues'],
        },
      };
    }

    return new Promise((res, rej) => {
      module.api
        .get({ id })
        .then(record => {
          if (module.configuration.form?.prepareFetchedRecord) {
            res(module.configuration.form.prepareFetchedRecord(record, allFields));
          } else {
            res(record);
          }
        })
        .catch(rej);
    });
  };

  if (!module.configuration.form) {
    return null;
  }

  if (isLoadingModuleConfig) {
    return <Loader />;
  }

  return (
    <ApiForm
      key={`api-form-${module.configuration.dataClass}-${id}-${readonly ? 1 : 0}-${showBackButton ? 1 : 0}-${
        showConfigurationSwitcher ? 1 : 0
      }`}
      className={`form-${module.configuration.urlPrefix}`}
      ref={ApiFormComponent}
      autoSave={overrideFormProps.autoSave ?? module.configuration.form.autoSave}
      recordCallback={overrideFormProps.recordCallback ? overrideFormProps.recordCallback : async allFields => fetch(allFields)}
      onSubmit={overrideFormProps.onSubmit ?? onSubmit}
      readonly={overrideFormProps.readonly ?? readonly}
      showBackButton={overrideFormProps.showBackButton ?? showBackButton}
      backUrl={overrideFormProps.backUrl ?? module.listUrl}
      showConfigurationSwitcher={overrideFormProps.showConfigurationSwitcher ?? showConfigurationSwitcher}
      recordLabel={overrideFormProps.recordLabel ?? module.configuration.recordLabel}
      customTabs={overrideFormProps.customTabs ?? module.configuration.form.customTabs}
      fieldComponents={overrideFormProps.fieldComponents ?? module.configuration.form.fieldComponents ?? {}}
      sectionComponents={overrideFormProps.sectionComponents ?? module.configuration.form.sectionComponents ?? {}}
      forceReadonlyField={overrideFormProps.forceReadonlyField ?? module.configuration.form.forceReadonlyField}
      onRecordChange={overrideFormProps.onRecordChange ?? module.configuration.form.onRecordChange}
      inlineComponentAppendHeader={overrideFormProps.inlineComponentAppendHeader ?? module.configuration.form.inlineComponentAppendHeader}
      resetTab={overrideFormProps.resetTab ?? false}
      trackTabInUrl={overrideFormProps.trackTabInUrl ?? true}
      contextActions={record => (
        <>
          {<CustomContextActions readonly={readonly} id={record.id} record={record} ApiFormComponent={ApiFormComponent} />}
          {showContextActions && <ContextActions readonly={readonly} row={record} />}
        </>
      )}
      submitActions={record => (
        <CustomSubmitActions readonly={readonly} id={record.id} record={record} ApiFormComponent={ApiFormComponent} />
      )}
    />
  );
};

const SecuredModuleView = props => {
  const module = useModuleContext<any, true>(props.moduleName, null, true);

  const role = module?.configuration.role
    ? `ROLE_EDIT_${module?.configuration.role}|ROLE_SHOW_${module?.configuration.role}`
    : 'ROLE_EMPLOYEE';

  return (
    <SecuredView role={role} displayError={true}>
      <ModuleProvider value={module}>
        <ModuleForm {...props} />
      </ModuleProvider>
    </SecuredView>
  );
};

export default SecuredModuleView;
