import { InvoiceStatus } from '@Accountancy/Invoice/InvoiceStatuses';
import SettlementComponent from '@Accountancy/Settlement/Components/List/IsClosedColumn';
import SettlementBlock from '@Accountancy/Settlement/Components/List/SettlementBlock';
import CTMModule, { CTMListColumn, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';

export type SettlementRow = {
  status: InvoiceStatus;
  documents: number;
  revenue: number;
  revenueVat: number;
  expense: number;
  expenseVat: number;
};

export type Settlement = {
  settlements: Record<InvoiceStatus, SettlementRow>;
};

export type MonthlySettlementReport = CTMStrictRecord & {
  id: string;
  year: number;
  month: number;
  monthlySettlement: MonthlySettlement;
  settlement: Settlement;
};

export type MonthlySettlement = CTMStrictRecord & {
  id: string;
  year: number;
  month: number;
  settlement: Settlement;
  isClosed: boolean;
};

const columns: CTMListColumn<MonthlySettlementReport>[] = [
  {
    id: 'isClosed',
    Header: 'Zamknięty',
    accessor: ({ year, month, monthlySettlement }) => (
      <SettlementComponent
        monthlySettlement={monthlySettlement?.id || null}
        settlementYear={year}
        settlementMonth={month}
        isLocked={true === monthlySettlement?.isClosed}
      />
    ),
    filterable: false,
    sortable: false,
  },
  {
    id: 'year',
    Header: 'Rok',
    accessor: 'year',
    filterable: false,
    sortable: false,
  },
  {
    id: 'month',
    Header: 'Miesiąc',
    accessor: 'month',
    filterable: false,
    sortable: false,
  },
  {
    id: 'settlement',
    Header: 'Rozliczenie',
    accessor: row => <SettlementBlock settlement={row} />,
    filterable: false,
    sortable: false,
  },
];

export type MonthlySettlementModule = CTMModule<MonthlySettlementReport>;

const module: MonthlySettlementModule = {
  id: 'c39e5e5a-08f6-4f3a-a501-607ae29badae',
  dataClass: 'CTM\\Accountancy\\Entity\\MonthlySettlement',
  urlPrefix: 'accountancy-settlement-monthly-settlement',
  name: 'Miesięczne rozliczenia',
  role: 'ACCOUNTANCY_SETTLEMENT',
  api: {
    item: {
      get: ({ id }) => `/accountancy/monthly-settlements/${id}`,
      put: ({ id }) => `/accountancy/monthly-settlements/${id}`,
      delete: ({ id }) => `/accountancy/monthly-settlements/${id}`,
    },
    collection: {
      get: `/accountancy/reports/accountancy-monthly-reports`,
      post: `/accountancy/monthly-settlements`,
    },
  },
  recordLabel: department => `${department.year}-${department.month}`,
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
