import ExportButton from './Button/ExportButton';
import ViewSwitch from '@Components/Common/ViewSwitch';
import DataGridRow from '@Components/DataGrid/Row/DataGridRow';
import Loader from '@Components/Theme/Common/Loader';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import classnames from 'classnames';
import { getColumns, getColumnsConfiguration } from 'common/hooks/Grid/Configuration';
import Configurator from 'components/DataGrid/Configurator';
import { DefaultColumnFilter, Filter } from 'components/DataGrid/Filter';
import Breadcrumbs from 'components/Theme/Common/Breadcrumb';
import { Col, Container } from 'components/Theme/Grid';
import { useModuleContext } from 'context/ModulesContext';
import { get } from 'helpers/Axios';
import _ from 'lodash';
import { ArrowDownNarrowWide, ArrowDownUp, ArrowDownWideNarrow, Hammer, X } from 'lucide-react';
import { ComponentRef, Fragment, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Column, useExpanded, useFilters, useRowSelect, useSortBy, useTable } from 'react-table';
import { usePrevious } from 'react-use';

const IndeterminateCheckbox = forwardRef((props, ref) => {
  // @ts-ignore
  const { indeterminate, ...rest } = props;
  const defaultRef = useRef<HTMLInputElement>();
  const resolvedRef = (ref || defaultRef) as typeof defaultRef;

  useEffect(() => {
    // @ts-ignore
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  // @ts-ignore
  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});
IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';
const useStyles = makeStyles({
  sticky: {
    position: 'sticky',
    right: 0,
    boxShadow: 'inset 1px 0px 0px 0px #dedede',
    background: 'inherit',
  },
  stickyRow: {
    position: 'sticky',
    top: -2,
    background: 'inherit',
    zIndex: 1,
    boxShadow: 'inset 1px -1px 0px 0px #dedede',
  },
});

export type DataGridProps = {
  columns: Column[];
  url: string;
  className?: string;
  allowAdd?: boolean;
  addUrl?: string;
  refresh?: any;
  selectedIds?: string[];
  selectionActions?: any;
  responsive?: boolean;
  allowChangeLimit?: boolean;
  disableTop?: boolean;
  disableHeader?: boolean;
  enableContextMenu?: boolean;
  disableFooter?: boolean;
  disableColumnActions?: boolean;
  fullWidth?: boolean;
  defaultPerPage?: number;
  perPageOptions?: number[];
  renderRowSubComponent?: any;
  canRenderSubRow?: any;
  expandAll?: any;
  defaultFilters?: any[];
  exports?: any[];
  defaultOrderBy?: any[];
  headerOverride?: any;
  onFilterUpdate?: any;
  onRowClick?: any;
  rowStylesCallback?: any;
  queryKey?: any;
  minRows?: number;
  storeFilters?: any;
  manipulateFiltersBeforeSend?: any;
  prependButton?: any;
};

export type DataGridHandle = {
  refresh: () => void;
  setAllFilters: (parameters: any[]) => void;
  setSortBy: (...parameters: any) => void;
  getAllFilters: () => any;
  getSortBy: () => any;
  getData: () => any;
};

export type FilterValue = {
  id: string;
  value: string;
};

const DataGrid = forwardRef<DataGridHandle, DataGridProps>((props, ref) => {
  const {
    columns,
    url,
    className,
    allowAdd,
    addUrl,
    refresh,
    enableContextMenu = false,
    selectedIds = [],
    selectionActions: SelectionActionsComponent,
    responsive = true,
    allowChangeLimit = true,
    disableTop = false,
    disableHeader = false,
    disableFooter = false,
    disableColumnActions = false,
    fullWidth = false,
    defaultPerPage = 20,
    perPageOptions = [10, 20, 30, 40, 50],
    renderRowSubComponent = undefined,
    canRenderSubRow = undefined,
    expandAll = undefined,
    defaultFilters = [],
    exports = [],
    defaultOrderBy = [{ id: 'id', desc: true }],
    headerOverride = undefined,
    onFilterUpdate = undefined,
    onRowClick = undefined,
    rowStylesCallback = undefined,
    queryKey = null,
    minRows = 3,
    storeFilters,
    manipulateFiltersBeforeSend,
    prependButton,
  } = props;
  const cacheKey = (): string => {
    return `_ctm_grid_V1_${window.btoa(url)}${queryKey}`;
  };

  const { module } = useParams<{ module?: string }>();
  const crudModule = useModuleContext<any, false>(module ?? '');

  const [perPage, setPerPage] = useState(defaultPerPage);
  const [page, setPage] = useState<number>(parseInt(localStorage.getItem(cacheKey() + '_page') ?? '1'));
  const [hiddenColumnsLocally, setHiddenColumnsLocally] = useState([]);
  const visibleColumns = getColumnsConfiguration(columns, url);
  const history = useHistory<any>();
  const classes = useStyles();
  const [controlledFilters, setControlledFilters] = useState<FilterValue[]>([]);
  const [controlledData, setControlledData] = useState([]);
  const [controlledDataLength, setControlledDataLength] = useState<number>(0);

  const buildURLQuery = data =>
    Object.entries(data)
      .map(pair => pair.join('='))
      .join('&');

  const fetchData = async ({ signal }) => {
    let sortData = {};
    const filtersData = (manipulateFiltersBeforeSend ? manipulateFiltersBeforeSend(filters) : filters).map(item => {
      if (Array.isArray(item.value)) {
        return item.value.reduce((acc, val) => {
          acc[`${item.id}[${Object.values(acc).length}]`] = encodeURIComponent(val);
          return acc;
        }, {});
      }

      if (typeof item.value === 'object' && !Array.isArray(item.value)) {
        return item.value;
      }

      return { [item.id]: encodeURIComponent(item.value) };
    });

    if (sortBy[0]) {
      const sortItem = sortBy[0];
      sortData = {
        ['order[' + sortItem.id + ']']: sortItem.desc ? 'DESC' : 'ASC',
      };
    }

    const fullUrl =
      url +
      (url.includes('?') ? '&' : '?') +
      buildURLQuery({
        limit: perPage,
        page: page,
        ...Object.assign({}, ...filtersData),
        ...sortData,
      });

    if (storeFilters) {
      localStorage.setItem(cacheKey(), JSON.stringify(filters));
      localStorage.setItem(cacheKey() + 'sort', JSON.stringify(sortBy));
    }
    return await get(fullUrl, { signal });
  };

  const handleExport = (exportId: string, exportUrl: string | null) => {
    return new Promise<boolean>((resolve, reject) => {
      let sortData = {};
      const filtersData = (manipulateFiltersBeforeSend ? manipulateFiltersBeforeSend(filters) : filters).map(item => {
        if (Array.isArray(item.value)) {
          return item.value.reduce((acc, val) => {
            acc[`${item.id}[${Object.values(acc).length}]`] = encodeURIComponent(val);
            return acc;
          }, {});
        }
        return { [item.id]: encodeURIComponent(item.value) };
      });

      if (sortBy[0]) {
        const sortItem = sortBy[0];
        sortData = {
          ['order[' + sortItem.id + ']']: sortItem.desc ? 'DESC' : 'ASC',
        };
      }

      const fullUrl =
        (exportUrl ?? url) +
        (url.includes('?') ? '&' : '?') +
        buildURLQuery({
          limit: perPage,
          export: exportUrl ? null : exportId,
          page: page,
          ...Object.assign({}, ...filtersData),
          ...sortData,
        });

      return get(fullUrl, {
        responseType: 'blob',
        headers: { accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      }).then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', exports.find(el => (el.id = exportId)).fileName + '.xlsx');
          document.body.appendChild(link);
          link.click();
          resolve(true);
        } else {
          console.error('Error while generating export');
          reject(false);
        }
      });
    });
  };

  const {
    data: { 'hydra:member': data, 'hydra:totalItems': totalItems },
    refetch,
    isLoading,
    // @ts-ignore
  } = useQuery(JSON.stringify([url, { refresh }, queryKey, JSON.stringify(controlledFilters)]), fetchData, {
    initialData: { 'hydra:member': [], 'hydra:totalItems': 0 },
  });

  useEffect(() => {
    if (data) {
      setControlledData(data);
      setControlledDataLength(totalItems);
    }
  }, [data, totalItems]);

  const totalPages = controlledDataLength > 0 ? Math.ceil(controlledDataLength / perPage) : 1;
  const nextPageAvailable = page < totalPages;
  const previousPageAvailable = page > 1;

  const onChangeInSelect = event => {
    setPerPage(Number(event.target.value));
  };

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) : 0;
    if (page > totalPages) {
      setPage(totalPages);
      return;
    }
    if (page < 1) {
      setPage(1);
      return;
    }
    setPage(page);
  };

  const generateSortingIndicator = column => {
    if (!column.sortable) {
      return null;
    }

    if (column.isSortedDesc === true) {
      return <ArrowDownWideNarrow size={18} />;
    }

    if (column.isSortedDesc === false) {
      return <ArrowDownNarrowWide size={18} />;
    }
    return <ArrowDownUp size={18} />;
  };

  const generateEditableIcon = column => {
    if (!column.editable) {
      return null;
    }

    return (
      <Tooltip title={'Kliknij dwukrotnie na wartość aby edytować.'}>
        <Hammer size={18} />
      </Tooltip>
    );
  };

  const firstPage = () => {
    setPage(1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const lastPage = () => {
    setPage(totalPages);
  };

  const resolveDefaultFilter = () => {
    try {
      if (storeFilters && localStorage.getItem(cacheKey())) {
        return JSON.parse(localStorage.getItem(cacheKey()) ?? '') || defaultFilters || [];
      }
    } catch (e) {
      console.error(e);
    }
    return defaultFilters || [];
  };

  const resolveDefaultSortBy = () => {
    try {
      if (storeFilters && localStorage.getItem(cacheKey() + 'sort')) {
        return JSON.parse(localStorage.getItem(cacheKey() + 'sort') ?? '') || defaultOrderBy || [];
      }
    } catch (e) {
      console.error(e);
    }
    return defaultOrderBy || [];
  };

  const getHiddenColumns = () => {
    const allColumns = getColumns(columns);
    return allColumns.filter(item => visibleColumns.indexOf(item) < 0).filter(item => item !== 'actionsStickyRight');
  };

  const defaultExpanded = {};

  if (expandAll) {
    controlledData.forEach((el, index) => {
      defaultExpanded[`${index}`] = true;
    });
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows,
    state: { sortBy, filters },
    setHiddenColumns,
    setAllFilters,
    setSortBy,
    setFilter,
  } = useTable(
    {
      columns,
      data: controlledData,
      defaultColumn: { Filter: DefaultColumnFilter, width: 'auto' },
      manualSortBy: true,
      manualFilters: true,
      initialState: {
        expanded: defaultExpanded,
        filters: [...resolveDefaultFilter()],
        sortBy: [...resolveDefaultSortBy()],
        selectedRowIds: data
          .map((item, index) => ({ index: index, state: selectedIds.includes(item['@id']) }))
          .reduce((acc, item) => {
            if (item.state) {
              acc[item.index] = true;
            }
            return acc;
          }, {}),
      },
    },
    hooks => {
      if (SelectionActionsComponent) {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => {
              const inheritedProps = row.getToggleRowSelectedProps();
              return (
                <div
                  onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                >
                  <IndeterminateCheckbox
                    {...inheritedProps}
                    onClick={event => {
                      event.stopPropagation();
                      event.preventDefault();
                      inheritedProps.onChange(event);
                    }}
                  />
                </div>
              );
            },
          },
          ...columns,
        ]);
      }
    },
    useFilters,
    useSortBy,
    useExpanded,
    useRowSelect,
  );

  const previousFilters = usePrevious(filters);
  const previousSortBy = usePrevious(sortBy);

  useEffect(() => {
    if (
      typeof previousFilters === 'undefined' ||
      typeof previousSortBy === 'undefined' ||
      (JSON.stringify(previousFilters) === JSON.stringify(filters) && JSON.stringify(previousSortBy) === JSON.stringify(sortBy))
    ) {
      return;
    }

    setPage(1);
  }, [filters, sortBy]);

  const add = () => {
    addUrl && history.push(addUrl);
  };

  useEffect(() => {
    refetch();
    onFilterUpdate && onFilterUpdate({ page, perPage, sortBy, filters });
  }, [page, perPage, sortBy, filters, refresh]);

  useEffect(() => {
    setControlledFilters(filters);
  }, [filters]);

  useEffect(() => {
    localStorage.setItem(cacheKey() + '_page', page + '');
  }, [page]);

  useEffect(() => {
    if (hiddenColumnsLocally.length !== getHiddenColumns().length) {
      setHiddenColumnsLocally(getHiddenColumns());
      refetch();
    }
  }, [visibleColumns]);

  useEffect(() => {
    setHiddenColumns(hiddenColumnsLocally);
  }, [hiddenColumnsLocally]);

  useImperativeHandle(
    ref,
    (): DataGridHandle => ({
      refresh() {
        refetch();
        onFilterUpdate && onFilterUpdate({ page, perPage, sortBy, filters });
      },
      setAllFilters(parameters: any[]) {
        setAllFilters(parameters);
      },
      setSortBy(...paremeters) {
        setSortBy(...paremeters);
      },
      getAllFilters() {
        return filters;
      },
      getSortBy() {
        return sortBy;
      },
      getData() {
        return data;
      },
    }),
  );

  if (isLoading) {
    return <Loader />;
  }

  const PerPageSelectorElement = props => {
    return (
      <select {...props} className="form-select" value={perPage} onChange={onChangeInSelect}>
        {perPageOptions.map(perPage => (
          <option key={perPage} value={perPage}>
            Pokaż {perPage}
          </option>
        ))}
        {!perPageOptions.includes(defaultPerPage) && (
          <option key={perPage} value={perPage}>
            Pokaż {perPage}
          </option>
        )}
      </select>
    );
  };

  const AddButtonElement = props => (
    <div className="d-flex gap-3">
      <Button {...props} color="success" variant="contained" onClick={add} size="small" className="me-2">
        <i className="mdi mdi-plus" />
        &nbsp;Dodaj
      </Button>
    </div>
  );

  const HeaderOverride = headerOverride;
  const PrependButton = prependButton;

  const ConfiguratorElement = props => <Configurator {...props} columns={columns} url={url} />;

  return (
    <Fragment>
      {!disableTop && (
        <Container spacing={0} columnSpacing={0} style={{ padding: '0 1px' }}>
          <Col
            xs={12}
            className="w-100"
            style={{
              background: '#edf0f5',
              border: '1px solid rgb(222 222 222)',
              borderRight: 'none',
            }}
          >
            {headerOverride && (
              <HeaderOverride
                PerPageSelectorElement={PerPageSelectorElement}
                AddButtonElement={AddButtonElement}
                ConfiguratorElement={ConfiguratorElement}
                allowChangeLimit={allowChangeLimit}
                allowAdd={allowAdd}
                setFilter={setFilter}
              />
            )}
            {!headerOverride && (
              <div style={{ padding: 6 }}>
                <Container className="w-100 d-flex justify-content-between align-items-center" spacing={0}>
                  {crudModule && <Breadcrumbs breadcrumbItem={`${crudModule.configuration.name} - lista`} />}
                  <div className="d-flex gap-3">
                    {SelectionActionsComponent && selectedFlatRows.length > 0 && (
                      <Col style={{ maxWidth: 250 }}>
                        <div className="text-sm-end">
                          <SelectionActionsComponent selectedFlatRows={selectedFlatRows} />
                        </div>
                      </Col>
                    )}
                    {storeFilters && filters.length > 1 && (
                      <Col style={{ maxWidth: 250 }}>
                        <Button
                          className="btn btn-danger d-flex align-center"
                          onClick={() => {
                            setAllFilters([]);
                          }}
                        >
                          <X size={18} />
                          Wyczyść filtry
                        </Button>
                      </Col>
                    )}
                    <Col style={{ maxWidth: 250 }}>
                      <div className="text-sm-end">{prependButton && <PrependButton />}</div>
                    </Col>
                    <Col style={{ maxWidth: 250 }}>
                      <div className="text-sm-end">
                        <ExportButton exports={exports} onExport={handleExport} />
                      </div>
                    </Col>
                    <Col style={{ maxWidth: 250 }}>
                      <div className="text-sm-end d-flex">
                        {allowAdd && <AddButtonElement />}
                        <ConfiguratorElement />
                      </div>
                    </Col>
                    {crudModule?.configuration.list?.board && (
                      <Col>
                        <ViewSwitch />
                      </Col>
                    )}
                  </div>
                </Container>
              </div>
            )}
          </Col>
        </Container>
      )}
      {disableTop && SelectionActionsComponent && selectedFlatRows.length > 0 && (
        <Container spacing={0} columnSpacing={0} style={{ padding: '0 1px' }}>
          <Col
            xs={12}
            className="w-100"
            style={{
              background: '#edf0f5',
              border: '1px solid rgb(222 222 222)',
              borderRight: 'none',
            }}
          >
            <SelectionActionsComponent selectedFlatRows={selectedFlatRows} />
          </Col>
        </Container>
      )}
      <div className={classNames('react-table', { 'table-responsive': responsive, 'w-100': fullWidth })}>
        <Table {...getTableProps()} className={`data-grid table-hover table-striped ${className || ''}`}>
          {!disableHeader && (
            <TableHead className="table-nowrap">
              {headerGroups.map((headerGroup, index) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={`${queryKey}_${headerGroup.getHeaderGroupProps().key}_${index}`}>
                  {headerGroup.headers
                    .filter(column => typeof column.forceHidden !== 'function' || !column.forceHidden(column))
                    .map((column, index) => (
                      <TableCell
                        variant={'head'}
                        {...column.getHeaderProps([])}
                        style={{
                          verticalAlign: 'middle',
                          minWidth: column.minWidth ?? column.width ?? 120,
                          maxWidth: column.maxWidth ?? column.width ?? 500,
                          width: column.width ?? 'auto',
                          padding: '4px',
                          fontSize: 'normal',
                          ...(column.getHeaderProps([]).style ?? {}),
                        }}
                        size={'small'}
                        className={classnames(classes.stickyRow, {
                          [classes.sticky]: column.id === 'actionsStickyRight',
                          'sticky-header-column': column.id === 'actionsStickyRight',
                        })}
                        key={`header_${queryKey}_${column.getHeaderProps([]).key}_${column.id}_${index}`}
                      >
                        <Container spacing={0}>
                          <Col xs={12}>
                            <Container
                              spacing={0}
                              style={{
                                minHeight: !column.filterable && !column.sortable ? 'initial' : '60px',
                                position: 'relative',
                                textAlign: 'center',
                                display: 'flex',
                                flexWrap: 'nowrap',
                                alignItems: 'center',
                                gap: '6px',
                              }}
                            >
                              <Col xs={12}>
                                {!disableColumnActions && column.filterable && (
                                  <Filter key={`${queryKey}_${column.id}_filter_${index}`} column={column} />
                                )}
                                {(disableColumnActions || !column.filterable) && <>{column.render('Header')}</>}
                              </Col>
                              {!disableColumnActions && column.sortable && (
                                <Col
                                  {...column.getSortByToggleProps()}
                                  style={{
                                    margin: '0 auto',
                                  }}
                                  justifyContent={'center'}
                                >
                                  {generateSortingIndicator(column)}
                                </Col>
                              )}
                              {!disableColumnActions && column.editable && (
                                <Col
                                  {...column.getSortByToggleProps()}
                                  style={{
                                    margin: '0 auto',
                                  }}
                                  justifyContent={'center'}
                                >
                                  {generateEditableIcon(column)}
                                </Col>
                              )}
                            </Container>
                          </Col>
                        </Container>
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableHead>
          )}

          <TableBody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <DataGridRow
                  key={`row_${queryKey}_${row.getRowProps().key}_${rowIndex}`}
                  row={row}
                  rowIndex={rowIndex}
                  queryKey={queryKey}
                  enableContextMenu={enableContextMenu}
                  canRenderSubRow={canRenderSubRow}
                  renderRowSubComponent={renderRowSubComponent}
                  onRowClick={onRowClick}
                  rowStylesCallback={rowStylesCallback}
                  refetch={refetch}
                />
              );
            })}
            {!disableHeader && minRows - rows.length > 0 && (
              <>
                {_.range(0, minRows - rows.length).map((i, index) => (
                  <TableRow key={`subrow_${queryKey}_${index}`}>
                    {headerGroups[0].headers
                      .filter(column => typeof column.forceHidden !== 'function' || !column.forceHidden(column))
                      .map((column, subCellIndex) => (
                        <TableCell key={`subcell_${queryKey}_${column.id}_${subCellIndex}`}>&nbsp;</TableCell>
                      ))}
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </div>

      {!disableFooter && (
        <Container
          className="data-grid-footer-pagination"
          justifyContent={'space-between'}
          alignItems={'center'}
          style={{ padding: '6px 12px' }}
        >
          <Col style={{ maxWidth: 140 }}>{allowChangeLimit && <PerPageSelectorElement />}</Col>
          <Col>
            <Container justifyContent={'flex-end'} alignItems={'center'}>
              <Col>
                <div className="d-flex gap-1">
                  <Button color="primary" size="small" variant="contained" onClick={firstPage} disabled={!previousPageAvailable}>
                    {'<<'}
                  </Button>
                  <Button color="primary" size="small" variant="contained" onClick={previousPage} disabled={!previousPageAvailable}>
                    {'<'}
                  </Button>
                </div>
              </Col>
              <Col className="pages-information">
                <strong>
                  {page} z {totalPages}
                </strong>
              </Col>
              <Col>
                <div className="d-flex gap-1">
                  <Button color="primary" size="small" variant="contained" onClick={nextPage} disabled={!nextPageAvailable}>
                    {'>'}
                  </Button>
                  <Button color="primary" size="small" variant="contained" onClick={lastPage} disabled={!nextPageAvailable}>
                    {'>>'}
                  </Button>
                </div>
              </Col>
            </Container>
          </Col>
        </Container>
      )}
    </Fragment>
  );
});
DataGrid.displayName = 'DataGrid';

export type DataGridRef = ComponentRef<typeof DataGrid>;
export default DataGrid;
