import { InvoiceTypes } from '@Accountancy/Invoice/InvoiceTypes';
import { Invoice, InvoiceConfiguration } from '@Accountancy/Invoice/Types/invoice';
import NumberInput from '@Components/Form/MUI/NumberInput';
import TextInput from '@Components/Form/MUI/TextInput';
import { Col, Container } from '@Components/Theme/Grid';
import useModuleConfigSave from '@Core/Hooks/useModuleConfigSave';
import { Alert, Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useModule } from 'context/ModuleContext';
import { memo, useState } from 'react';

interface ConfigurationFormProps {
  configuration: InvoiceConfiguration['InvoiceNumeration'];
  setConfiguration: (props: InvoiceConfiguration['InvoiceNumeration']) => any;
}

function ConfigurationForm(props: ConfigurationFormProps) {
  const { configuration, setConfiguration } = props;
  return (
    <Container>
      <Col xs={12} md={6}>
        <Alert color="info">
          Aby zapisać własny format numeracji dokumentów , stwórz wzór w oparciu o generowane automatycznie wartości:
          <br />
          <br />
          <ul>
            <li>
              Numer dokumentu - wymagany
              <ul>
                <li>
                  Uwzględniając departament
                  <ul>
                    <li>
                      Liczony uwzględniając wszystkie typy
                      <ul>
                        <li>%DNy% - numer dokumentu w roku</li>
                        <li>%DNm% - numer dokumentu w miesiącu</li>
                        <li>%DNd% - numer dokumentu w dniu</li>
                      </ul>
                    </li>
                    <li>
                      Liczony uwzględniając tylko aktualny typ dokumentu
                      <ul>
                        <li>%Dny% - numer dokumentu w roku</li>
                        <li>%Dnm% - numer dokumentu w miesiącu</li>
                        <li>%Dnd% - numer dokumentu w dniu</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Bez uwzględnienia departamenu
                  <ul>
                    <li>
                      Liczony uwzględniając wszystkie typy
                      <ul>
                        <li>%Ny% - numer dokumentu w roku</li>
                        <li>%Nm% - numer dokumentu w miesiącu</li>
                        <li>%Nd% - numer dokumentu w dniu</li>
                      </ul>
                    </li>
                    <li>
                      Liczony uwzględniając tylko aktualny typ dokumentu
                      <ul>
                        <li>%ny% - numer dokumentu w roku</li>
                        <li>%nm% - numer dokumentu w miesiącu</li>
                        <li>%nd% - numer dokumentu w dniu</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Rok
              <ul>
                <li>%Y% - pełny (np. 2022)</li>
                <li>%y% - dwie ostatnie cyfry (np. 22)</li>
              </ul>
            </li>
            <li>
              Miesiąc
              <ul>
                <li>%M% - z zerem (np. 06)</li>
                <li>%m% - bez zera (np. 6)</li>
              </ul>
            </li>
            <li>
              Dzień
              <ul>
                <li>%D% - z zerem (np. 01)</li>
                <li>%d% - bez zera (np. 1)</li>
              </ul>
            </li>
            <li>
              Departament
              <ul>
                <li>%DC% - Kod departamentu (np. D)</li>
              </ul>
            </li>
          </ul>
          <br />
          Przykłady:
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Format</TableCell>
                <TableCell variant="head">Wynik</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>%DTs%/%R%/%M%/%nm%</TableCell>
                <TableCell>PZ/2022/06/12345</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>%DTs%/%r%/%m%/%nm%</TableCell>
                <TableCell>PZ/22/6/12345</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>%R%/%Ny%</TableCell>
                <TableCell>2022/54214</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Alert>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <NumberInput
          value={configuration.numberLength}
          onChange={val => setConfiguration({ ...configuration, numberLength: val ?? 0 })}
          label="Ilość zer wiodących w liczbie dokumentów"
        />
      </Col>
      <Col xs={12} md={6}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Typ</TableCell>
              <TableCell>Numeracja</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {InvoiceTypes.map(el => (
              <TableRow key={el.type}>
                <TableCell>{el.label}</TableCell>
                <TableCell>
                  <TextInput
                    value={configuration.types[el.type]}
                    onChange={val => setConfiguration({ ...configuration, types: { ...configuration.types, [el.type]: val } })}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Col>
    </Container>
  );
}

const MemoConfigurationForm = memo(ConfigurationForm);

function InvoiceNumerationConfiguration() {
  const { module, moduleConfig } = useModule<Invoice, InvoiceConfiguration>();
  const { isLoading, mutate: updateModule } = useModuleConfigSave<InvoiceConfiguration>(module.configuration.id);
  const [configuration, setConfiguration] = useState<InvoiceConfiguration['InvoiceNumeration']>(moduleConfig.InvoiceNumeration);
  const onSave = () => {
    updateModule({ ...moduleConfig, InvoiceNumeration: configuration });
  };

  return (
    <>
      <Box>
        <MemoConfigurationForm configuration={configuration} setConfiguration={setConfiguration} />
      </Box>

      <Box sx={{ flexGrow: 1 }} justifyContent="end" display="flex" flex="1 100%" padding={2}>
        <Button variant="contained" color="success" onClick={onSave} disabled={isLoading}>
          Zapisz
        </Button>
      </Box>
    </>
  );
}

export default InvoiceNumerationConfiguration;
