import { sumPayments, sumPositions } from '@Accountancy/Invoice/Invoice';
import { InvoiceStatus } from '@Accountancy/Invoice/InvoiceStatuses';
import CorrectionInvoicePositionsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/CorrectionInvoicePositionsGroup';
import CorrectionNoteInvoiceGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/CorrectionNoteInvoiceGroup';
import InvoiceCommentsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceCommentsGroup';
import InvoiceContractorFieldsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceContractorFieldsGroup';
import InvoiceCorrectionInfoGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceCorrectionInfoGroup';
import InvoiceDateFieldsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDateFieldsGroup';
import InvoiceGeneralFieldsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceGeneralFieldsGroup';
import InvoiceLocationFieldsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceLocationFieldsGroup';
import InvoicePaymentsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoicePaymentsGroup';
import InvoicePositionsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoicePositionsGroup';
import useInvoiceFields, { InvoiceFields } from '@Accountancy/Invoice/Sections/useInvoiceFields';
import { InvoicePayment, InvoicePosition, InvoiceType } from '@Accountancy/Invoice/Types/invoice';
import { CustomSectionComponent } from '@Components/CustomFields/CustomSection';
import { Col, Container } from '@Components/Theme/Grid';
import classnames from 'classnames';
import { useModuleFields } from 'context/ModuleContext';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import { useAppDispatch } from 'store';
import { addSingleToast } from 'store/Toast/actions';

export type InvoiceGroupProps = {
  fields: InvoiceFields;
  values: { [key: string]: any };
  onUpdate: (value: any, field: string) => void;
  readonly: boolean;
};

const PaymentsInvoices: InvoiceType[] = ['VAT', 'PROFORMA', 'ADVANCE', 'CORRECTION'];

const InvoiceDefaultSection: CustomSectionComponent = props => {
  const { section, values, onUpdate, readonly, useCollapse } = props;
  const moduleFields = useModuleFields();
  const [expanded, setExpanded] = useState(true);
  const indexedNamedFields = useInvoiceFields(moduleFields);
  const dispatch = useAppDispatch();
  const type: InvoiceType = values[indexedNamedFields.type.id];

  useEffect(() => {
    const status: InvoiceStatus = values[indexedNamedFields.status.id];
    const totalPrice = sumPositions(Object.values(values[indexedNamedFields.positions.id]), 'priceTotalGross');
    const now = moment().format('YYYY-MM-DD HH:mm:ss');
    if (status === 'PAID') {
      const paid = sumPayments(Object.values(values[indexedNamedFields.payments.id]), 'priceTotalGross');

      if (paid < totalPrice) {
        const payments: InvoicePayment[] = [
          ...values[indexedNamedFields.payments.id],
          {
            transaction: '',
            paymentType: 'BANK_TRANSFER',
            priceTotalGross: totalPrice - paid,
            receivedDate: now,
          },
        ];

        dispatch(
          addSingleToast({
            title: `Dodano nową transakcję na kwotę ${totalPrice - paid}`,
            config: { appearance: 'success' },
          }),
        );
        onUpdate(payments, indexedNamedFields.payments.id);
      }

      if (values[indexedNamedFields.paymentDate.id] === null) {
        dispatch(addSingleToast({ title: 'Ustawiono datę płatności', config: { appearance: 'success' } }));
        onUpdate(now, indexedNamedFields.paymentDate.id);
      }
    }
  }, [values[indexedNamedFields.status.id]]);

  useEffect(() => {
    const status: InvoiceStatus = values[indexedNamedFields.status.id];
    const positions: InvoicePosition[] = Object.values(values[indexedNamedFields.positions.id]);
    const payments: InvoicePayment[] = Object.values(values[indexedNamedFields.payments.id]);
    if (payments.length === 0 || positions.length === 0) {
      return;
    }
    const totalPrice = sumPositions(positions, 'priceTotalGross');
    const now = moment().format('YYYY-MM-DD HH:mm:ss');
    const paid = sumPayments(payments, 'priceTotalGross');

    if (paid.toFixed(2) === totalPrice.toFixed(2)) {
      if (values[indexedNamedFields.paymentDate.id] === null) {
        dispatch(addSingleToast({ title: 'Ustawiono datę płatności', config: { appearance: 'success' } }));
        onUpdate(now, indexedNamedFields.paymentDate.id);
      }
      if ((['PARTIAL', 'ISSUED'] as InvoiceStatus[]).includes(status)) {
        dispatch(addSingleToast({ title: 'Zmieniono status na "Opłacona"', config: { appearance: 'success' } }));
        onUpdate('PAID', indexedNamedFields.status.id);
      }
    }
  }, [JSON.stringify(values[indexedNamedFields.payments.id])]);

  return (
    <>
      <div className={classnames({ 'accordion-item cf-tab': useCollapse })} key={section.id}>
        {useCollapse && (
          <h2 className="accordion-header cf-tab" id={section.id}>
            <div
              className={classnames('accordion-button', { collapsed: !expanded })}
              onClick={() => setExpanded(!expanded)}
              style={{ cursor: 'pointer' }}
            >
              {section.name || 'Nazwa sekcji'}
            </div>
          </h2>
        )}
        <Collapse isOpen={expanded} className="accordion-collapse custom-field-section-collapse">
          <div
            className={classnames('custom-section', { 'accordion-body': useCollapse })}
            style={{ background: '#f5f5f9', padding: '30px 12px' }}
          >
            <Container>
              {type === 'CORRECTION' && (
                <Col xs={12} sx={{ marginBottom: 2 }}>
                  <InvoiceCorrectionInfoGroup fields={indexedNamedFields} values={values} onUpdate={onUpdate} readonly={readonly} />
                </Col>
              )}
              <Col xs={12} md={6} sx={{ marginBottom: 2 }}>
                <InvoiceGeneralFieldsGroup fields={indexedNamedFields} values={values} onUpdate={onUpdate} readonly={readonly} />
              </Col>
              <Col xs={12} md={6} sx={{ marginBottom: 2 }}>
                <Container>
                  <Col xs={12}>
                    <InvoiceDateFieldsGroup fields={indexedNamedFields} values={values} onUpdate={onUpdate} readonly={readonly} />
                  </Col>
                  <Col xs={12}>
                    <InvoiceLocationFieldsGroup fields={indexedNamedFields} values={values} onUpdate={onUpdate} readonly={readonly} />
                  </Col>
                </Container>
              </Col>
              <Col xs={12} md={6} sx={{ marginBottom: 2 }}>
                <InvoiceContractorFieldsGroup
                  fieldPrefix="seller"
                  fields={indexedNamedFields}
                  values={values}
                  onUpdate={onUpdate}
                  readonly={readonly}
                />
              </Col>
              <Col xs={12} md={6} sx={{ marginBottom: 2 }}>
                <InvoiceContractorFieldsGroup
                  fieldPrefix="buyer"
                  fields={indexedNamedFields}
                  values={values}
                  onUpdate={onUpdate}
                  readonly={readonly}
                />
              </Col>
              {type !== 'CORRECTION_NOTE' && (
                <Col xs={12} sx={{ marginBottom: 2 }}>
                  {type === 'CORRECTION' && (
                    <CorrectionInvoicePositionsGroup fields={indexedNamedFields} values={values} onUpdate={onUpdate} readonly={readonly} />
                  )}
                  {type !== 'CORRECTION' && (
                    <InvoicePositionsGroup fields={indexedNamedFields} values={values} onUpdate={onUpdate} readonly={readonly} />
                  )}
                </Col>
              )}
              {type === 'CORRECTION_NOTE' && (
                <Col xs={12} sx={{ marginBottom: 2 }}>
                  <CorrectionNoteInvoiceGroup fields={indexedNamedFields} values={values} onUpdate={onUpdate} readonly={readonly} />
                </Col>
              )}
              {type !== 'CORRECTION_NOTE' && (
                <Col xs={12} sx={{ marginBottom: 2 }}>
                  <InvoiceCommentsGroup fields={indexedNamedFields} values={values} onUpdate={onUpdate} readonly={readonly} />
                </Col>
              )}
              {PaymentsInvoices.includes(type) && (
                <Col xs={12} sx={{ marginBottom: 2 }}>
                  <InvoicePaymentsGroup fields={indexedNamedFields} values={values} onUpdate={onUpdate} readonly={readonly} />
                </Col>
              )}
            </Container>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default InvoiceDefaultSection;
