import { useModule } from '../../../../../context/ModuleContext';
import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import { InvoiceFields } from '@Accountancy/Invoice/Sections/useInvoiceFields';
import useIsMonthLocked from '@Accountancy/Settlement/Hooks/useIsMonthLocked';
import CustomField, { IndexedField } from '@Components/CustomFields/CustomField';
import { Col } from '@Components/Theme/Grid';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';

export type InvoiceFieldProps<T = any> = InvoiceGroupProps & {
  fieldKey: keyof InvoiceFields;
  fieldOverride?: Partial<IndexedField>;
  size?: number;
  customProps?: any;
  valueWasChanged?: (field: IndexedField, newValue: null | T, prevValue: null | T) => void;
};

const arr = [];
const obj = {};
const enabledFieldsInLockedMode: string[] = ['status', 'payments', 'externalNumber', 'lang', 'secondLang', 'paymentDate'];

function InvoiceField<T>(props: InvoiceFieldProps<T>) {
  const { fields, values, onUpdate, readonly, fieldKey, fieldOverride, size, valueWasChanged, customProps } = props;
  const { module } = useModule();
  const issueDate = dayjs(values[fields.issueDate.id]);
  const isMonthLocked = useIsMonthLocked(issueDate.year(), issueDate.month() + 1);

  const currentValue = useMemo(() => values[fields[fieldKey].id], [values[fields[fieldKey].id]]);

  const handleUpdate = useCallback(
    (value: any, field: string) => {
      valueWasChanged?.(fields[fieldKey], value, currentValue);
      onUpdate(value, field);
    },
    [fields, fieldKey, currentValue],
  );

  const field = useMemo(() => ({ ...fields[fieldKey], ...(fieldOverride ?? {}) }), []);
  return (
    <Col xs={size ?? 12} sx={{ marginBottom: 0 }}>
      <Box sx={{ backgroundColor: '#fefefe', width: '100%', position: 'relative' }}>
        <CustomField
          field={field}
          fields={arr}
          value={currentValue}
          values={obj}
          onUpdate={handleUpdate}
          readonly={
            readonly ||
            module.configuration.form?.forceReadonlyField?.(field, values, Object.values(fields), '') ||
            (isMonthLocked && !enabledFieldsInLockedMode.includes(field.propertyPath))
          }
          components={module.configuration.form?.fieldComponents ?? obj}
          size={12}
          recordId={null}
          customProps={customProps}
        />
      </Box>
    </Col>
  );
}

export default InvoiceField;
