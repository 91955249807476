import { useModule } from 'context/ModuleContext';

export default function useModuleShowRecordURL(id: string | number | null | undefined): string {
  const { module } = useModule();

  if (!id || !module.configuration.form || module.configuration.form.disableShowRecord) {
    return '';
  }

  return module.showUrl(id);
}
