import { TransactionModule } from '../../../CTMModules';
import ModuleList from 'components/Module/ModuleList';
import { ModuleProvider } from 'context/ModuleContext';
import { FC } from 'react';

interface TransactionTab {
  record: any;
}

const TransactionTab: FC<TransactionTab> = ({ record }) => {
  return (
    <ModuleProvider value={TransactionModule}>
      <ModuleList
        key={TransactionModule.configuration.urlPrefix}
        moduleName={TransactionModule.configuration.urlPrefix}
        defaultFilters={[{ id: 'clientOrder.id', value: record.id }]}
        useModal={false}
        overrideListProps={{ storeFilters: false }}
      />
    </ModuleProvider>
  );
};

export default TransactionTab;
