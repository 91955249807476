import TooltipField from '@Components/DataGrid/Field/TooltipField';
import ImageBox from '@Components/Image/ImageBox';
import { Box } from '@mui/material';
import { FC } from 'react';

interface ProductNameProps {
  name?: string;
  image?: string;
}

const ProductName: FC<ProductNameProps> = ({ name, image }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {image && <ImageBox url={image} previewImages={[image]} config={{ width: 250, quality: 90 }} />}
      <Box sx={{ ml: 1 }}>
        <TooltipField text={name || ''} trim={25} />
      </Box>
    </div>
  );
};

export default ProductName;
