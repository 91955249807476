import useImagesOptimizer from '@Core/Hooks/useImagesOptimizer';
import Avatar from '@mui/material/Avatar';
import { useDispatch } from 'react-redux';
import { setImages, toggle } from 'store/Lightbox/Lightbox';

export const DEFAULT_IMAGE_CONFIG: ImageConfig = {
  quality: 80,
  width: 500,
};

export type ImageConfig = {
  quality: number;
  width: number;
};

export type ImageBoxProps = {
  url: string;
  previewImages: string[];
  config?: ImageConfig;
};

function ImageBox(props: ImageBoxProps) {
  const { url, previewImages, config = DEFAULT_IMAGE_CONFIG } = props;
  const dispatch = useDispatch<any>();
  const thumbOptimizer = useImagesOptimizer(config);
  const bigOptimizer = useImagesOptimizer({ width: 1280, quality: 80 });

  const initPreview = (event): void => {
    event.stopPropagation();

    dispatch(setImages({ images: [...previewImages.map(bigOptimizer)] }));
    dispatch(toggle());
  };

  return <Avatar alt={'N'} src={thumbOptimizer(url)} onClick={initPreview} variant="rounded" sx={{ border: '1px solid #969696' }} />;
}

export default ImageBox;
