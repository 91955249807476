import { statusLabel } from '@Accountancy/Invoice/InvoiceStatuses';
import { MonthlySettlementReport } from '@Accountancy/Settlement/MonthlySettlement';
import MoneyView from '@Components/View/MoneyView';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

type SettlementBlockProps = {
  settlement: MonthlySettlementReport;
};

function SettlementBlock({ settlement }: SettlementBlockProps) {
  const settlementData = settlement.monthlySettlement?.isClosed ? settlement.monthlySettlement.settlement : settlement.settlement;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>W statusie</TableCell>
          <TableCell>Liczba dokumentów</TableCell>
          <TableCell>Przychód Netto</TableCell>
          <TableCell>VAT od przychodu</TableCell>
          <TableCell>Wydatki</TableCell>
          <TableCell>VAT od wydatków</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.values(settlementData.settlements).map(data => (
          <TableRow key={`${settlement['@id']}_${data.status}`}>
            <TableCell>{statusLabel(data.status)}</TableCell>
            <TableCell>{data.documents}</TableCell>
            <TableCell>
              <MoneyView value={data.revenue} currency={'PLN'} />
            </TableCell>
            <TableCell>
              <MoneyView value={data.revenueVat} currency={'PLN'} />
            </TableCell>
            <TableCell>
              <MoneyView value={data.expense} currency={'PLN'} />
            </TableCell>
            <TableCell>
              <MoneyView value={data.expenseVat} currency={'PLN'} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default SettlementBlock;
