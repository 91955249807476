import { useModule } from '../../../../../context/ModuleContext';
import { sumPositions } from '@Accountancy/Invoice/Invoice';
import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import { InvoicePosition } from '@Accountancy/Invoice/Types/invoice';
import useIsMonthLocked from '@Accountancy/Settlement/Hooks/useIsMonthLocked';
import NumberInput from '@Components/Form/MUI/NumberInput';
import TextInput from '@Components/Form/MUI/TextInput';
import useFormError from '@Components/Form/useFormError';
import ModuleListPicker from '@Components/Module/ModuleListPicker';
import { Col, Container } from '@Components/Theme/Grid';
import TaxesMenu from '@Core/Accountancy/Components/TaxesMenu';
import { Product } from '@Manufacture/Product';
import { DeleteForeverOutlined, VerticalAlignBottomOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {
  Alert,
  Box,
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { FC } from 'react';

type PositionsRowProps = InvoiceGroupProps & {
  position: InvoicePosition;
  index: number;
  readonly: boolean;
  onRowRemove: (index: number) => void;
  onRowUpdate: (index: number, position: InvoicePosition) => void;
};

const FooterCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.9rem',
}));
const PlaceholderCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
}));

const roundMoney = (value: string | number) => parseFloat(`${parseFloat(`${value}`).toFixed(2)}`);

export const computeNewPositionState = (newValue, changedField: keyof InvoicePosition, currentState: InvoicePosition): InvoicePosition => {
  switch (changedField) {
    case 'priceTotalGross':
      return computeNewPositionState(roundMoney(newValue / (1 + (currentState.tax?.value ?? 0) / 100)), 'priceTotalNet', currentState);
    case 'priceTotalNet':
      return computeNewPositionState(roundMoney(newValue / currentState.quantity), 'priceUnitNet', currentState);
    case 'quantity':
      return {
        ...currentState,
        quantity: newValue,
        priceTotalNet: roundMoney(currentState.priceUnitNet * newValue),
        priceUnitGross: roundMoney(currentState.priceUnitNet * (1 + (currentState.tax?.value ?? 0) / 100)),
        priceTotalGross: roundMoney(currentState.priceUnitNet * newValue * (1 + (currentState.tax?.value ?? 0) / 100)),
      };
    case 'priceUnitNet':
      return {
        ...currentState,
        priceUnitNet: roundMoney(newValue),
        priceTotalNet: roundMoney(newValue * currentState.quantity),
        priceUnitGross: roundMoney(newValue * (1 + (currentState.tax?.value ?? 0) / 100)),
        priceTotalGross: roundMoney(newValue * currentState.quantity * (1 + (currentState.tax?.value ?? 0) / 100)),
      };
    case 'tax':
      return {
        ...currentState,
        tax: newValue,
        priceUnitGross: roundMoney(currentState.priceUnitNet * (1 + (newValue?.value ?? 0) / 100)),
        priceTotalGross: roundMoney(currentState.priceUnitNet * currentState.quantity * (1 + (newValue?.value ?? 0) / 100)),
      };
    default:
      return { ...currentState, [changedField]: newValue };
  }
};

const PositionsRow: FC<PositionsRowProps> = props => {
  const currency: string = props.values?.[props.fields.currency.id] ?? [];
  const exchangeCurrency: string = props.values?.[props.fields.exchangeCurrency.id] ?? [];

  const handleInputChange = (value, name: keyof InvoicePosition) => {
    props.onRowUpdate(props.index, computeNewPositionState(value, name, props.position));
  };

  const handleProductSelect = (row: null | Product) => {
    if (!row) {
      props.onRowUpdate(props.index, { ...props.position, product: null, name: '' });
      return;
    }

    props.onRowUpdate(props.index, {
      ...props.position,
      product: row,
      name: row.name ?? '',
      unit: row.unit,
      code: row.symbol,
      ean: row.ean,
    });
  };

  return (
    <>
      <TableRow>
        {!props.readonly && (
          <TableCell sx={{ width: 40, textAlign: 'center' }}>
            <DeleteForeverOutlined color="error" cursor="pointer" onClick={() => props.onRowRemove(props.index)} />
          </TableCell>
        )}
        <TableCell sx={{ width: 40, textAlign: 'center' }}>{props.position.seq}</TableCell>
        <TableCell>
          <TextInput
            disabled={props.readonly}
            value={props.position.name}
            onChange={val => handleInputChange(val, 'name')}
            inputProps={{
              endAdornment: !props.readonly && (
                <InputAdornment position="end">
                  <ModuleListPicker<Product>
                    disabled={props.readonly}
                    moduleName={'manufacture-products'}
                    onChange={handleProductSelect}
                    readerValue={null}
                  >
                    <ListAltIcon color="primary" cursor="pointer" />
                  </ModuleListPicker>
                </InputAdornment>
              ),
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 190 }}>
          <TextInput disabled={props.readonly} value={props.position.code} onChange={val => handleInputChange(val, 'code')} />
        </TableCell>
        <TableCell sx={{ width: 190 }}>
          <TextInput disabled={props.readonly} value={props.position.ean} onChange={val => handleInputChange(val, 'ean')} />
        </TableCell>
        <TableCell sx={{ width: 80 }}>
          <NumberInput
            disabled={props.readonly}
            value={props.position.quantity}
            onChange={val => handleInputChange(val, 'quantity')}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ModuleListPicker
                    disabled={props.readonly}
                    moduleName={'manufacture-units'}
                    onChange={val => handleInputChange(val, 'unit')}
                    readerValue={props.position.unit}
                  >
                    {props.position.unit?.name ?? 'Szt.'}
                  </ModuleListPicker>
                </InputAdornment>
              ),
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 170 }}>
          <NumberInput
            disabled={props.readonly}
            value={props.position.priceUnitNet}
            onChange={val => handleInputChange(val, 'priceUnitNet')}
            inputProps={{
              endAdornment: <InputAdornment position="end">{props.values[props.fields.currency.id]}</InputAdornment>,
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 100 }}>
          <TaxesMenu
            value={props.position.tax?.['@id'] ?? null}
            onChange={val => handleInputChange(val.data, 'tax')}
            disabled={props.readonly}
          />
        </TableCell>
        <TableCell sx={{ width: 170 }}>
          <NumberInput
            disabled={props.readonly}
            value={props.position.priceTotalNet}
            onChange={val => handleInputChange(val, 'priceTotalNet')}
            inputProps={{
              endAdornment: <InputAdornment position="end">{props.values[props.fields.currency.id]}</InputAdornment>,
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 170 }}>
          <NumberInput
            disabled={props.readonly}
            value={props.position.priceTotalGross}
            onChange={val => handleInputChange(val, 'priceTotalGross')}
            inputProps={{
              endAdornment: <InputAdornment position="end">{props.values[props.fields.currency.id]}</InputAdornment>,
            }}
          />
        </TableCell>
      </TableRow>
      {exchangeCurrency !== currency && (
        <TableRow>
          {!props.readonly && <TableCell sx={{ width: 40, textAlign: 'center' }}></TableCell>}
          <TableCell sx={{ width: 40, textAlign: 'center' }}></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell sx={{ width: 80 }}></TableCell>
          <TableCell sx={{ width: 170 }}>
            <NumberInput
              disabled={true}
              value={parseFloat((props.position.priceUnitNet * props.values[props.fields.exchangeRate.id]).toFixed(2))}
              onChange={val => handleInputChange(val, 'priceUnitNet')}
              inputProps={{
                endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
              }}
            />
          </TableCell>
          <TableCell sx={{ width: 100 }}></TableCell>
          <TableCell sx={{ width: 170 }}>
            <NumberInput
              disabled={true}
              value={parseFloat((props.position.priceTotalNet * props.values[props.fields.exchangeRate.id]).toFixed(2))}
              onChange={val => handleInputChange(val, 'priceTotalNet')}
              inputProps={{
                endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
              }}
            />
          </TableCell>
          <TableCell sx={{ width: 170 }}>
            <NumberInput
              disabled={true}
              value={parseFloat((props.position.priceTotalGross * props.values[props.fields.exchangeRate.id]).toFixed(2))}
              onChange={val => handleInputChange(val, 'priceTotalGross')}
              inputProps={{
                endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
              }}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const PositionsGroup: FC<InvoiceGroupProps & { hideLabel?: boolean }> = props => {
  const positions: InvoicePosition[] = Object.values(props.values?.[props.fields.positions.id] ?? []);
  const currency: string = props.values?.[props.fields.currency.id] ?? [];
  const exchangeCurrency: string = props.values?.[props.fields.exchangeCurrency.id] ?? [];
  const [hasErrors, errorMessage] = useFormError('positions');
  const { module } = useModule();
  const issueDate = dayjs(props.values[props.fields.issueDate.id]);
  const isMonthLocked = useIsMonthLocked(issueDate.year(), issueDate.month() + 1);

  const addNewRow = () => {
    props.onUpdate(
      [...positions, { quantity: 1, tax: positions?.[0]?.tax ?? null, seq: Math.max(1, ...positions.map(el => el.seq)) + 1 }],
      props.fields.positions.id,
    );
  };

  const handeRemove = (index: number) => {
    props.onUpdate(
      positions.filter((_, i) => i !== index).map((el, index) => ({ ...el, seq: index + 1 })),
      props.fields.positions.id,
    );
  };
  const handleUpdate = (index: number, position: InvoicePosition) => {
    props.onUpdate(
      positions.map((el, i) => (i === index ? position : el)),
      props.fields.positions.id,
    );
  };

  const readonly: boolean =
    isMonthLocked ||
    props.readonly ||
    (module.configuration.form?.forceReadonlyField?.(props.fields.positions, props.values, Object.values(props.fields), '') ?? false);

  return (
    <Paper>
      <Container>
        {!props.hideLabel && (
          <Col xs={12}>
            <Typography variant="subtitle1" align="center">
              Pozycje
            </Typography>
            {hasErrors && <Alert severity="error">{errorMessage}</Alert>}
          </Col>
        )}
        <Col xs={12} sx={{ padding: 2 }}>
          <Box sx={{ padding: 0, maxHeight: 500, overflowY: 'auto' }}>
            <Table padding="none">
              <TableHead sx={{ backgroundColor: '#f5f5f5', position: 'sticky', top: 0, zIndex: 20 }}>
                <TableRow>
                  {!readonly && <TableCell sx={{ width: 40, textAlign: 'center' }}></TableCell>}
                  <TableCell sx={{ width: 40, textAlign: 'center' }}>lp.</TableCell>
                  <TableCell>Nazwa</TableCell>
                  <TableCell>Symbol</TableCell>
                  <TableCell>EAN</TableCell>
                  <TableCell sx={{ width: 150 }}>Ilość</TableCell>
                  <TableCell sx={{ width: 170 }}>Cenna netto</TableCell>
                  <TableCell sx={{ width: 100 }}>
                    <TaxesMenu
                      value={null}
                      onChange={val =>
                        props.onUpdate(
                          positions.map(el => computeNewPositionState(val.data, 'tax', el)),
                          props.fields.positions.id,
                        )
                      }
                      disabled={readonly}
                    >
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        Vat <VerticalAlignBottomOutlined />
                      </Box>
                    </TaxesMenu>
                  </TableCell>
                  <TableCell sx={{ width: 170 }}>Wartość netto</TableCell>
                  <TableCell sx={{ width: 170 }}>Wartość brutto</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {positions.map((el, index) => (
                  <PositionsRow
                    key={index}
                    {...props}
                    readonly={readonly}
                    position={el}
                    index={index}
                    onRowRemove={handeRemove}
                    onRowUpdate={handleUpdate}
                  />
                ))}
              </TableBody>
              {!readonly && (
                <TableFooter sx={{ backgroundColor: '#fff', position: 'sticky', bottom: 0, zIndex: 20 }}>
                  <TableRow>
                    <PlaceholderCell></PlaceholderCell>
                    <PlaceholderCell></PlaceholderCell>
                    <TableCell>
                      <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => addNewRow()} fullWidth={true}>
                        Dodaj pozycję
                      </Button>
                    </TableCell>
                    <PlaceholderCell colSpan={7}></PlaceholderCell>
                  </TableRow>
                  <TableRow>
                    <PlaceholderCell colSpan={10}></PlaceholderCell>
                  </TableRow>
                  <TableRow>
                    <PlaceholderCell colSpan={7}></PlaceholderCell>
                    <FooterCell>Suma Netto</FooterCell>
                    <FooterCell sx={{ textAlign: 'right', paddingRight: 1 }}>
                      {sumPositions(positions, 'priceTotalNet').toFixed(2)} {currency !== exchangeCurrency && `${currency}`}
                    </FooterCell>
                    <FooterCell sx={{ textAlign: currency == exchangeCurrency ? 'left' : 'right' }}>
                      {currency == exchangeCurrency && `${currency}`}
                      {currency !== exchangeCurrency &&
                        `${(sumPositions(positions, 'priceTotalNet') * props.values[props.fields.exchangeRate.id]).toFixed(
                          2,
                        )} ${exchangeCurrency}`}
                    </FooterCell>
                  </TableRow>
                  <TableRow>
                    <PlaceholderCell colSpan={7}></PlaceholderCell>
                    <FooterCell>Suma VAT</FooterCell>
                    <FooterCell sx={{ textAlign: 'right', paddingRight: 1 }}>
                      {(sumPositions(positions, 'priceTotalGross') - sumPositions(positions, 'priceTotalNet')).toFixed(2)}{' '}
                      {currency !== exchangeCurrency && `${currency}`}
                    </FooterCell>
                    <FooterCell sx={{ textAlign: currency == exchangeCurrency ? 'left' : 'right' }}>
                      {currency == exchangeCurrency && `${currency}`}
                      {currency !== exchangeCurrency &&
                        `${(
                          (sumPositions(positions, 'priceTotalGross') - sumPositions(positions, 'priceTotalNet')) *
                          props.values[props.fields.exchangeRate.id]
                        ).toFixed(2)} ${exchangeCurrency}`}
                    </FooterCell>
                  </TableRow>
                  <TableRow>
                    <PlaceholderCell colSpan={7}></PlaceholderCell>
                    <FooterCell>Suma Brutto</FooterCell>
                    <FooterCell sx={{ textAlign: 'right', paddingRight: 1 }}>
                      {sumPositions(positions, 'priceTotalGross').toFixed(2)} {currency !== exchangeCurrency && `${currency}`}
                    </FooterCell>
                    <FooterCell sx={{ textAlign: currency == exchangeCurrency ? 'left' : 'right' }}>
                      {currency == exchangeCurrency && `${currency}`}
                      {currency !== exchangeCurrency &&
                        `${(sumPositions(positions, 'priceTotalGross') * props.values[props.fields.exchangeRate.id]).toFixed(
                          2,
                        )} ${exchangeCurrency}`}
                    </FooterCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </Box>
        </Col>
      </Container>
    </Paper>
  );
};

export default PositionsGroup;
