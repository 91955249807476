import { confirmAction } from '../../../common/sweetalerts';
import { ContextAction } from '@Core/Types/CTMModule';
import { BaseLinkerIntegration } from '@Integration/BaseLinkerIntegration';
import { BaseLinkerIntegrationModule } from '@Modules/CTMModules';
import { CleaningServices, InfoOutlined, SettingsEthernet, SyncAltRounded, SyncOutlined } from '@mui/icons-material';

type BaseLinkerContextAction = {
  icon: JSX.Element;
  label: string;
  description: string;
  action: (integrationId: string) => Promise<Record<string, never>>;
};

const BaseLinkerActions: BaseLinkerContextAction[] = [
  {
    icon: <SyncAltRounded color="success" />,
    label: 'Synchronizuj Kategorie',
    description: 'Akcja ta spowoduje wgranie wszystkich kategorii do BaseLinker',
    action: integrationId => BaseLinkerIntegrationModule.api.custom.registerActionForSynchronizeProductGroups(integrationId),
  },
  {
    icon: <SettingsEthernet color="success" />,
    label: 'Połącz produkty',
    description: 'Akcja to połączu istniejące produkty z produktami z BaseLinker na podstawię powiązania kodu EAN',
    action: integrationId => BaseLinkerIntegrationModule.api.custom.registerActionForConnectProducts(integrationId),
  },
  {
    icon: <SyncOutlined color="warning" />,
    label: 'Synchronizuj produkty',
    description: 'Akcja ta spowoduje wgranie wszystkich produktów do BaseLinker',
    action: integrationId => BaseLinkerIntegrationModule.api.custom.registerActionForSynchronizeProducts(integrationId),
  },
  {
    icon: <CleaningServices color="error" />,
    label: 'Wyczyść powiązania produktów',
    description: 'Akcja ta spowoduje usunięcie wszystkich powiązań kategorii z BaseLinker',
    action: integrationId => BaseLinkerIntegrationModule.api.custom.registerActionForWipeProductsConnections(integrationId),
  },
  {
    icon: <CleaningServices color="error" />,
    label: 'Wyczyść powiązania kategorii',
    description: 'Akcja ta spowoduje usunięcie wszystkich powiązań produktów z BaseLinker',
    action: integrationId => BaseLinkerIntegrationModule.api.custom.registerActionForWipeProductGroupsConnections(integrationId),
  },
];

export function useBaseLinkerContextActions(record: Partial<BaseLinkerIntegration>, readonly: boolean): ContextAction[] {
  const doAction = (action: BaseLinkerContextAction) => {
    confirmAction(
      () => action.action(record.id as string),
      action.label,
      `${action.description}\nCzy na pewno chcesz wykonać tę akcję?`,
      true,
      'Akcja została zaplanowana i zostanie wykonana w tle.',
      false,
    );
  };

  const actions: ContextAction[] = [];

  if (readonly && record.id && record.active) {
    BaseLinkerActions.forEach(action => {
      actions.push({
        icon: <>{action.icon}</>,
        label: action.label,
        action: () => doAction(action),
      });
    });
  } else {
    actions.push({
      icon: <InfoOutlined />,
      label: 'Włącz integracje aby wyświetlić dostępne opcje',
      action: () => {},
    });
  }

  return actions;
}
