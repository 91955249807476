import ColoredIconBox from '@Components/Theme/Common/ColoredIconBox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MouseEvent, PropsWithChildren, useState } from 'react';

export type MenuPickerOption<OptionType = number | string, DataBag = undefined> = {
  label: string;
  value: OptionType;
  background?: null | string;
  icon?: null | string;
  color?: string | null;
  data?: DataBag;
};

export type MenuPickerProps<OptionType = number | string, DataBag = undefined> = {
  value: MenuPickerOption<OptionType> | OptionType;
  options: MenuPickerOption<OptionType, DataBag>[];
  onChange?: (value: MenuPickerOption<OptionType, DataBag>) => void;
  defaultBackground?: string | null;
  defaultColor?: string | null;
  disabled?: boolean;
};

export default function MenuPicker<OptionType = number | string, DataBag = undefined>(
  props: PropsWithChildren<MenuPickerProps<OptionType, DataBag>>,
) {
  const { value, options, onChange, defaultBackground, children, defaultColor, disabled } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectValue = (option: MenuPickerOption<OptionType, DataBag>) => {
    onChange?.(option);
    handleClose();
  };

  const selectedOption = value
    ? options.find(option => {
        if (typeof value === 'object' && value.hasOwnProperty('value')) {
          return option.value === (value as MenuPickerOption<OptionType, DataBag>).value;
        }

        return option.value === value;
      })
    : null;

  return (
    <div>
      {!selectedOption ? (
        <div onClick={handleClick}>{children}</div>
      ) : (
        <ColoredIconBox
          value={selectedOption.label}
          background={disabled ? '#eff2f7' : selectedOption.background ?? defaultBackground}
          icon={selectedOption.icon}
          color={selectedOption.color ?? defaultColor}
          onClick={handleClick}
          sx={{ cursor: 'pointer' }}
        />
      )}
      {!disabled && (
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {options.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => handleSelectValue(option)}
              selected={selectedOption?.value === option.value}
              sx={{ padding: '4px' }}
            >
              <ColoredIconBox
                value={option.label}
                background={option.background ?? defaultBackground}
                color={option.color ?? defaultColor}
                icon={option.icon}
                reverseColors={selectedOption?.value === option.value}
                sx={{
                  cursor: 'pointer',
                  border: 1,
                  borderColor: option.background ?? defaultBackground,
                  borderStyle: 'groove',
                }}
              />
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
}
