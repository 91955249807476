import { useModuleDeleteAction, useModuleEditRecordURL, useModuleShowRecordURL } from '@Core/Hooks/Module';
import { CTMRecord } from '@Core/Types/CTMModule';
import { DeleteForeverOutlined, EditOutlined, MoreVertOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListSubheader, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import { MenuProps } from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem';
import useRecordLabel from 'Modules/Core/Hooks/Module/useRecordLabel';
import { useModule } from 'context/ModuleContext';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface ModuleContextButtonProps<T extends CTMRecord = any> {
  record: T;
  afterDelete?: () => void;
}

export function ModuleContextButton(props: ModuleContextButtonProps) {
  const { record, afterDelete } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleContextMenu = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <IconButton onClick={handleContextMenu} color="primary" size="small" sx={{ border: 1, background: '#fefefe' }}>
      <MoreVertOutlined />
      <ModuleContextMenu
        menuProps={{
          anchorEl: anchorEl,
          open: anchorEl !== null,
        }}
        record={record}
        onClose={handleClose}
        afterDelete={afterDelete}
      />
    </IconButton>
  );
}

interface ModuleContextMenuProps<T extends CTMRecord = any> {
  menuProps: MenuProps;
  record: T;
  onClose: () => void;
  afterDelete?: () => void;
}

function ModuleContextMenu<T extends CTMRecord = any>(props: ModuleContextMenuProps<T>) {
  const { record, onClose, menuProps, afterDelete } = props;
  const { module } = useModule();
  const history = useHistory();
  const recordLabel = useRecordLabel(record);
  const showRecordURL = useModuleShowRecordURL(record.id);
  const editRecordURL = useModuleEditRecordURL(record.id);
  const deleteRecordAction = useModuleDeleteAction(() => afterDelete?.());
  const useActions = module.configuration.form?.useContextActions ?? (() => []);
  const actionProps = { module, readonly: true, id: record.id ?? '', record: record, ApiFormComponent: null };
  const newActions = useActions(actionProps);

  const menus = useMemo<JSX.Element[]>(() => {
    const tmp: JSX.Element[] = [];

    if ('' !== showRecordURL) {
      tmp.push(
        <MenuItem
          key={`row_${record?.['@id'] ?? record?.id}_show`}
          onClick={() => {
            onClose();
            history.push(showRecordURL);
          }}
          sx={{
            background: '#fefefe',
          }}
        >
          <ListItemIcon>
            <VisibilityOutlined sx={theme => ({ color: theme.palette.primary.main })} />
          </ListItemIcon>
          <Typography variant="inherit">Podgląd</Typography>
        </MenuItem>,
      );
    }

    if ('' !== editRecordURL) {
      tmp.push(
        <MenuItem
          key={`row_${record?.['@id'] ?? record?.id}_edit`}
          onClick={() => {
            onClose();
            history.push(editRecordURL);
          }}
          sx={{
            background: '#fefefe',
          }}
        >
          <ListItemIcon>
            <EditOutlined sx={theme => ({ color: theme.palette.secondary.main })} />
          </ListItemIcon>
          <Typography variant="inherit">Edytuj</Typography>
        </MenuItem>,
      );
    }

    if (undefined !== deleteRecordAction) {
      tmp.push(
        <MenuItem
          key={`row_${record?.['@id'] ?? record?.id}_delete`}
          onClick={() => {
            onClose();
            deleteRecordAction(record.id ?? '');
          }}
          sx={{
            background: '#fefefe',
          }}
        >
          <ListItemIcon>
            <DeleteForeverOutlined sx={theme => ({ color: theme.palette.error.main })} />
          </ListItemIcon>
          <Typography variant="inherit">Usuń</Typography>
        </MenuItem>,
      );
    }

    if ('length' in newActions && newActions.length > 0) {
      tmp.push(<ListSubheader key={`row_${record?.['@id'] ?? record?.id}_action_context`}>Akcje dodatkowe</ListSubheader>);
      newActions.forEach((action, actionIndex) => {
        tmp.push(
          <MenuItem
            sx={{
              background: '#fefefe',
            }}
            key={`row_${record?.['@id'] ?? record?.id}_action_context_${actionIndex}`}
            onClick={() => {
              onClose();
              action.action();
            }}
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            <Typography variant="inherit">{action.label}</Typography>
          </MenuItem>,
        );
      });
    }

    return tmp;
  }, [showRecordURL, editRecordURL, newActions]);

  return (
    <Menu
      {...menuProps}
      onClose={e => {
        onClose();
      }}
      onBackdropClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClose();
      }}
      MenuListProps={{
        sx: theme => ({
          overflow: 'hidden',
          margin: 0,
          border: 1,
          minWidth: 200,
          borderRadius: 1,
          paddingTop: 0,
          borderColor: theme.palette.primary.main,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        }),
      }}
    >
      {'' !== recordLabel && (
        <ListSubheader sx={theme => ({ background: theme.palette.primary.main, color: '#fff' })}>{recordLabel}</ListSubheader>
      )}
      {menus}
    </Menu>
  );
}

export default ModuleContextMenu;
