import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import InvoiceField from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceField';
import { InvoiceType } from '@Accountancy/Invoice/Types/invoice';
import { DateTimePickerProps } from '@Components/Form/MUI/DateTimePickerInput';
import { Col, Container } from '@Components/Theme/Grid';
import { Box, Button, ButtonGroup, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';

const PaymentDeadlineToolbar: DateTimePickerProps['ToolbarComponent'] = props => {
  const predefinedDates = [
    [
      { label: '3 dni', value: dayjs().add(3, 'day') },
      { label: '7 dni', value: dayjs().add(1, 'week') },
      { label: '14 dni', value: dayjs().add(2, 'week') },
    ],
    [
      { label: '21 dni', value: dayjs().add(3, 'week') },
      { label: 'miesiąc', value: dayjs().add(1, 'month') },
      { label: '2 miesiące', value: dayjs().add(2, 'month') },
    ],
  ];

  return (
    <>
      {predefinedDates.map((dateGroup, index) => (
        <Box key={index} sx={{ display: 'flex', gap: '10px', padding: 2, minWidth: '370px' }}>
          <ButtonGroup disableElevation variant="contained" fullWidth>
            {dateGroup.map(date => (
              <Button variant="outlined" key={date.label} onClick={() => props.handleChange(date.value)}>
                {date.label}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
      ))}
    </>
  );
};

const InvoiceDateFieldsGroup: FC<InvoiceGroupProps> = props => {
  const type: InvoiceType = props.values[props.fields.type.id];

  return (
    <Paper>
      <Container>
        <Col xs={12}>
          <Typography variant="subtitle1" align="center">
            Daty/Terminy
          </Typography>
        </Col>
        <Col xs={12} md={3}>
          <InvoiceField {...props} fieldKey={'issueDate'} />
        </Col>
        {type !== 'CORRECTION_NOTE' && (
          <>
            <Col xs={12} md={3}>
              <InvoiceField {...props} fieldKey={'sellDate'} />
            </Col>
            <Col xs={12} md={3}>
              <InvoiceField {...props} fieldKey={'paymentDeadline'} customProps={{ ToolbarComponent: PaymentDeadlineToolbar }} />
            </Col>
            <Col xs={12} md={3}>
              <InvoiceField {...props} fieldKey={'paymentDate'} />
            </Col>
          </>
        )}
      </Container>
    </Paper>
  );
};

export default InvoiceDateFieldsGroup;
