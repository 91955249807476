import CustomField from '@Components/CustomFields/CustomField';
import { CTMListColumn } from '@Core/Types/CTMModule';
import useSingleAndDoubleClick from 'common/hooks/useDoubleClick';
import { useModule, useModuleCustomField, useModuleFields } from 'context/ModuleContext';
import { FC, MouseEvent, PropsWithChildren, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';

type Cell = {
  render: (name: string) => JSX.Element;
  column: CTMListColumn;
};

interface EditableModuleFieldProps {
  id: string;
  fieldName: string;
  value: any;
  onChange: () => void;
}

const EditableModuleField: FC<PropsWithChildren<EditableModuleFieldProps>> = ({ id, fieldName, children, value, onChange }) => {
  const dispatch = useDispatch<any>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const crudModule = useModule();
  const moduleFields = useModuleFields();
  const field = useModuleCustomField(fieldName);

  const [currentValue, setCurrentValue] = useState<any>(value);

  const onDoubleClick = (e?: MouseEvent): void => {
    handleDoubleClick();
    e && e.stopPropagation();
  };

  const handleCellClick = useSingleAndDoubleClick(onDoubleClick);

  const handleDoubleClick = () => {
    if (isEditing) {
      return;
    }
    setIsEditing(true);
  };

  const onUpdate = (newValue: any): void => {
    setCurrentValue(newValue);
  };

  const onSave = (): void => {
    setIsEditing(false);

    crudModule.module.api
      .put({ [field.propertyPath]: currentValue }, { id: id })
      .then(() =>
        dispatch(
          addSingleToast({
            title: `Zapisano zmiany`,
            config: { appearance: 'success' },
          }),
        ),
      )
      .catch(data => {
        dispatch(
          addSingleToast({
            title: data?.response?.['hydra:description'] ?? 'Wystąpił błąd z zapisem',
            config: { appearance: 'error' },
          }),
        );
      })
      .finally(onChange);
  };

  return (
    <div onClick={handleCellClick}>
      {children}
      <Modal isOpen={isEditing} centered={true}>
        <ModalHeader>Edycja wartości</ModalHeader>
        <ModalBody>
          <CustomField
            field={field}
            size={12}
            fields={crudModule.moduleData.fieldsWithIndex}
            components={crudModule.module.configuration?.form?.fieldComponents ?? {}}
            value={currentValue}
            values={{}}
            onUpdate={onUpdate}
            readonly={crudModule.module.configuration?.form?.forceReadonlyField?.(field, {}, moduleFields, id ?? null) ?? false}
            recordId={id ?? null}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsEditing(false)}>
            Anuluj
          </Button>
          <Button color="success" onClick={onSave}>
            Zapisz
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditableModuleField;
