import ClearIcon from '@mui/icons-material/Clear';
import { Button, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';

type DateDifFilterValue = {
  diffFrom: null | number;
  diffTo: null | number;
  operator: null | 'afterDeadline' | 'beforeDeadline';
};

const MENU_OPTIONS = [
  { label: 'do 7 dni', diffFrom: 0, diffTo: 7 },
  { label: 'od 7 do 14 dni', diffFrom: 7, diffTo: 14 },
  { label: 'od 14 do 21 dni', diffFrom: 14, diffTo: 21 },
  { label: 'powyżej 30 dni', diffFrom: 22, diffTo: 9999 },
];

const OPERATOR_OPTIONS = [
  { label: 'Po terminie', operator: 'afterDeadline' as const },
  { label: 'Przed terminem', operator: 'beforeDeadline' as const },
];

function PaymentDeadlineFilter({ column }) {
  const [val, setVal] = useState<null | DateDifFilterValue>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const submenuOpen = Boolean(submenuAnchorEl);

  useEffect(() => {
    if (val === null || val.operator === null) {
      column.setFilter(null);
    } else if (val.diffFrom === null || val.diffTo === null) {
      column.setFilter({
        [`date_diff_filter[${column.filterPropertyName}][${val.operator === 'afterDeadline' ? 'gte' : 'lte'}]`]:
          val.operator === 'afterDeadline' ? 0 : -1,
      });
    } else {
      column.setFilter({
        [`date_diff_filter[${column.filterPropertyName}][gte]`]: val.operator === 'afterDeadline' ? val.diffFrom : -val.diffTo,
        [`date_diff_filter[${column.filterPropertyName}][lte]`]:
          val.operator === 'afterDeadline' ? val.diffTo : Math.min(-val.diffFrom, -1),
      });
    }
  }, [val]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubmenuAnchorEl(null);
  };

  const handleSubmenuOpen = (event: React.MouseEvent<HTMLLIElement>) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };

  const handleOperatorSelect = (operator: 'afterDeadline' | 'beforeDeadline') => {
    setVal(prev => ({
      diffFrom: prev?.diffFrom ?? null,
      diffTo: prev?.diffTo ?? null,
      operator,
    }));
    setSubmenuAnchorEl(null);
  };

  const handleDiffSelect = (diffFrom: number, diffTo: number) => {
    setVal(prev => ({
      operator: prev?.operator ?? 'afterDeadline',
      diffFrom,
      diffTo,
    }));
    handleMenuClose();
  };

  return (
    <>
      <Button onClick={handleMenuOpen} size="small">
        {val ? OPERATOR_OPTIONS.find(option => option.operator === val.operator)?.label : column.Header}{' '}
        {val ? MENU_OPTIONS.find(option => option.diffFrom === val.diffFrom && option.diffTo === val.diffTo)?.label : ''}
      </Button>
      {val !== null && (
        <Button onClick={() => setVal(null)} size="small">
          <ClearIcon />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {OPERATOR_OPTIONS.map(opt => (
          <MenuItem
            key={opt.label}
            onClick={event => {
              handleOperatorSelect(opt.operator);
              handleSubmenuOpen(event);
            }}
          >
            {opt.label}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={submenuAnchorEl}
        open={submenuOpen}
        onClose={handleSubmenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {MENU_OPTIONS.map(option => (
          <MenuItem key={option.label} onClick={() => handleDiffSelect(option.diffFrom, option.diffTo)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default PaymentDeadlineFilter;
