import { confirmRemove } from '../../common/sweetalerts';
import { useIsModuleContext } from '../../context/ModuleContext';
import { DataGridHandle } from '@Components/DataGrid';
import ModalFormWrapper, { ModalFormHandle } from '@Components/Form/ModalFormWrapper';
import { ModuleContextButton } from '@Components/Module/ModuleContextMenu';
import ModuleForm from '@Components/Module/ModuleForm';
import { ModuleListProps } from '@Components/Module/ModuleList';
import SecuredView from '@Components/Theme/Common/SecuredView';
import CRUDModule from '@Core/CRUDModule';
import CTMModule from '@Core/Types/CTMModule';
import { Tooltip } from '@mui/material';
import { Fragment, RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';

interface ModuleListActionsColumnProps {
  row: any;
  errorHandler: any;
  listRef: RefObject<DataGridHandle>;
  editModal: RefObject<ModalFormHandle>;
  showModal: RefObject<ModalFormHandle>;
  module: CRUDModule<CTMModule<any>>;
  override: any;
  overrideFormProps: ModuleListProps<any>['overrideFormProps'];
  useModal?: boolean;
  moduleFormWrapperProps?: any;
}

function ModalModuleListActionsColumn(props: ModuleListActionsColumnProps) {
  const { row, errorHandler, listRef, editModal, showModal, module, overrideFormProps, moduleFormWrapperProps } = props;

  return (
    <div className={'actions'}>
      <ButtonGroup>
        {module.configuration.form && (
          <>
            {!module.configuration.form.disableShowRecord && (
              <ModalFormWrapper
                {...moduleFormWrapperProps}
                ref={showModal}
                title={`${module.configuration.name} - ${module.configuration.recordLabel?.(row, [])} - Podgląd`}
                form={
                  <ModuleForm
                    moduleName={module.configuration.urlPrefix}
                    id={row.id}
                    showContextActions={false}
                    showBackButton={false}
                    showConfigurationSwitcher={false}
                    afterSave={() => {
                      showModal?.current?.close();
                      listRef?.current?.refresh();
                    }}
                    onError={errorHandler}
                    readonly={true}
                    overrideFormProps={{ ...overrideFormProps, recordCallback: undefined }}
                  />
                }
                wrapperComponent={Button}
                wrapperProps={{ className: 'btn btn-success btn-sm' }}
              >
                <Tooltip title={'Podgląd'} placement="top" arrow>
                  <i className="mdi mdi-eye" style={{ padding: '0 4px' }} />
                </Tooltip>
              </ModalFormWrapper>
            )}
            {!(module.configuration.form.disableEditRecord || overrideFormProps?.disableEditRecord) && (
              <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`}>
                <ModalFormWrapper
                  {...moduleFormWrapperProps}
                  ref={editModal}
                  title={`${module.configuration.name} - ${module.configuration.recordLabel?.(row, [])} - Edytuj`}
                  form={
                    <ModuleForm
                      moduleName={module.configuration.urlPrefix}
                      id={row.id}
                      showContextActions={false}
                      showBackButton={false}
                      afterSave={() => {
                        if (module.configuration.list?.closeAfterListAction ?? true) {
                          editModal?.current?.close();
                          listRef?.current?.refresh();
                        }
                      }}
                      onError={errorHandler}
                      overrideFormProps={{ ...overrideFormProps, recordCallback: undefined }}
                      readonly={false}
                    />
                  }
                  wrapperComponent={Button}
                  wrapperProps={{ className: 'btn btn-primary btn-sm', color: 'primary' }}
                >
                  <Tooltip title={'Edycja'} placement="top" arrow>
                    <i className="mdi mdi-cogs" style={{ padding: '0 4px' }} />
                  </Tooltip>
                </ModalFormWrapper>
              </SecuredView>
            )}
            {!(module.configuration.form.disableRemoveRecord || overrideFormProps?.disableRemoveRecord) && (
              <SecuredView role={`ROLE_REMOVE_${module.configuration.role}`}>
                <Button
                  onClick={() => confirmRemove(() => module.api.delete({ id: row.id }).then(() => listRef.current?.refresh()))}
                  className="btn btn-danger  btn-sm"
                >
                  <Tooltip title={'Usuń'} placement="top" arrow>
                    <i className="mdi mdi-delete" style={{ padding: '0 4px' }} />
                  </Tooltip>
                </Button>
              </SecuredView>
            )}
          </>
        )}
      </ButtonGroup>
    </div>
  );
}
function InlineModuleListActionsColumn(props: ModuleListActionsColumnProps) {
  const { row, listRef, module, override, overrideFormProps, useModal } = props;
  const EditWrapper = module.configuration.editButtonWrapper ?? Fragment;

  const isInNewModuleContext = useIsModuleContext();

  if (!useModal && isInNewModuleContext && module.configuration.form?.useContextActions) {
    return <ModuleContextButton record={row} />;
  }

  return (
    <ButtonGroup>
      {!overrideFormProps?.disableCustomActions &&
        module.configuration.list?.inlineComponentPrependActions &&
        module.configuration.list?.inlineComponentPrependActions(row, listRef.current)}
      {!(module.configuration.form?.disableShowRecord || overrideFormProps?.disableShowRecord) && (
        <Tooltip title={'Podgląd'} placement="top" arrow>
          <Link to={override?.showUrl ? override.showUrl(row) : module.showUrl(row.id)} className="btn btn-success btn-sm">
            <i className="mdi mdi-eye" />
          </Link>
        </Tooltip>
      )}
      {!(module.configuration.form?.disableEditRecord || overrideFormProps?.disableEditRecord) && (
        <EditWrapper {...(typeof module.configuration.editButtonWrapper === 'undefined' ? {} : { row })}>
          <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`}>
            <Link to={override?.editUrl ? override.editUrl(row) : module.editUrl(row?.id)} className="btn btn-primary btn-sm">
              <Tooltip title={'Edycja'} placement="top" arrow>
                <i className="mdi mdi-cogs" />
              </Tooltip>
            </Link>
          </SecuredView>
        </EditWrapper>
      )}
      {!(module.configuration.form?.disableRemoveRecord || overrideFormProps?.disableRemoveRecord) && (
        <SecuredView role={`ROLE_REMOVE_${module.configuration.role}`}>
          <Button
            onClick={() =>
              confirmRemove(() => module.api.delete({ id: row?.id }))
                .then(() => listRef.current?.refresh())
                .catch(() => {})
            }
            className="btn btn-danger  btn-sm"
          >
            <Tooltip title={'Usuń'} placement="top" arrow>
              <i className="mdi mdi-delete" />
            </Tooltip>
          </Button>
        </SecuredView>
      )}
      {!overrideFormProps?.disableCustomActions &&
        module.configuration.list?.inlineComponentAppendActions &&
        module.configuration.list?.inlineComponentAppendActions(row, listRef.current)}
    </ButtonGroup>
  );
}
function ModuleListActionsColumn(props: ModuleListActionsColumnProps) {
  const { useModal } = props;

  return useModal ? <ModalModuleListActionsColumn {...props} /> : <InlineModuleListActionsColumn {...props} />;
}

export default ModuleListActionsColumn;
