import { Tooltip } from '@mui/material';
import useApiErrorHandler from 'common/hooks/Api/useApiErrorHandler';
import { axiosApi } from 'helpers/Axios';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

type CopyItemButton = {
  id: string;
  copyUrl: string;
  editUrl: string;
  description: string;
};

const CopyItemButton: FC<CopyItemButton> = ({ id, copyUrl, editUrl, description }) => {
  const history = useHistory();
  const apiErrorHandler = useApiErrorHandler({ errorTitle: 'Wystąpił błąd podczas kopiowania.' });

  const copy = () => {
    axiosApi
      .post(copyUrl, { id: id })
      .then(response => {
        history.push(editUrl.replace('<id>', response.data.id));
      })
      .catch(apiErrorHandler);
  };

  return (
    <Button onClick={() => copy()} className="btn btn-secondary btn-sm">
      <Tooltip title={description} placement="top" arrow>
        <i className="mdi mdi-content-copy" />
      </Tooltip>
    </Button>
  );
};

export default CopyItemButton;
