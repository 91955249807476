import useFileDownloader from '@Core/Hooks/useFileDownloader';
import { Tooltip } from '@mui/material';
import classnames from 'classnames';
import SelectInput from 'components/Form/MUI/SelectInput';
import { FC, MouseEvent, useState } from 'react';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import { Button } from 'reactstrap';

type PrintItemButton = {
  id: string;
  name: string;
  downloadUrl: string;
  description: string;
  label: boolean;
  languageSelect?: boolean;
  parameters?: any;
  icon?: string;
  additionalClassnames?: string[];
  downloaded?: boolean;
};

const customStyles: StylesConfig = {
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    background: 'transparent',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  container: () => ({
    height: '38px',
    width: '100px',
    transition: 'max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    padding: '2px 8px 8px 2px',
    border: '1px solid rgba(0, 0, 0,0.23)',
    borderRadius: '4px',
    backgroundColor: 'white',
  }),
  menu: () => ({
    backgroundColor: 'white',
    boxShadow: '1px 2px 6px #888888', // should be changed as material-ui
    position: 'absolute',
    left: 0,
    top: `calc(100% + 1px)`,
    width: '100%',
    zIndex: 2,
    maxHeight: 48 * 4.5,
  }),
  menuList: () => ({
    maxHeight: 48 * 4.5,
    overflowY: 'auto',
  }),
};

const PrintItemButton: FC<PrintItemButton> = ({
  id,
  name,
  downloadUrl,
  description,
  label,
  languageSelect = false,
  parameters = [],
  icon = 'printer',
  additionalClassnames = [],
  downloaded = false,
}) => {
  const downloadFile = useFileDownloader({
    url: downloadUrl,
    fileName: `${name.replace(' ', '_')}_${id.replace(' ', '_')}`,
    config: {
      headers: {
        Accept: 'application/pdf',
      },
    },
  });
  const [language, setLanguage] = useState<string>('pl');
  const [isDownloaded, setDownloaded] = useState<boolean>(downloaded);

  const onClick = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setDownloaded(true);
    downloadFile({ params: { ...parameters, ...{ lang: language } } });
  };

  return (
    <>
      {languageSelect && (
        <div style={{ width: '100px', display: 'inline-block', position: 'relative', top: '-10px' }} className={'ml-1'}>
          <SelectInput
            value={language}
            options={[
              { value: 'pl', label: 'PL' },
              { value: 'en', label: 'EN' },
            ]}
            trackKey={'value'}
            name={'language'}
            labelKey={'label'}
            isClearable={false}
            onChange={value => setLanguage(String(value))}
            customStyles={customStyles}
          />
        </div>
      )}
      <Button
        onClick={e => onClick(e)}
        className={classnames(...additionalClassnames, 'btn btn-secondary', isDownloaded ? 'btn-success' : '', {
          'btn-sm': !label,
          'ml-1': label,
        })}
      >
        <Tooltip title={description}>
          <>
            <i className={classnames('mdi', 'mdi-' + icon)} />
            {label && ' Pobierz'} {label && name}
          </>
        </Tooltip>
      </Button>
    </>
  );
};

export default PrintItemButton;
